import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessCenterShareRoutingModule} from './business-center-share-routing.module';
import {BusinessCenterShareComponent} from './business-center-share.component';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [BusinessCenterShareComponent],
    imports: [
        CommonModule,
        BusinessCenterShareRoutingModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule
    ],
    exports: [BusinessCenterShareComponent]
})

export class BusinessCenterShareModule {
}
