import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class PcbMappingService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS(), data);
    }

    getByAdmin(universityId, data): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS_BY_ADMIN(universityId), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS(id));
    }

    detailByPCB(data): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS_DETAIL_BY_PCB(), data);
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.PCB_MAPPINGS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.PCB_MAPPINGS(id), data);
    }

    /*updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.PCB_MAPPINGS_PASSWORD(id), data);
    }*/

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.PCB_MAPPINGS_STATUS(id), data);
    }

    getByLanding(id, data): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS_BY_LANDING(id), data);
    }

    getBranchByLanding(id, data): Observable<any> {
        return this.getRequest(AppUrl.PCB_MAPPINGS_BRANCH_BY_LANDING(id), data);
    }
}
