import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class Helper {
    static round(value, decimal = 2, forceReturnNumber = true) {
        value = parseFloat(value);
        if (isNaN(value) && forceReturnNumber) {
            return 0;
        }
        return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
    }

    static fixedDecimals(value, decimal = 2, forceReturnNumber = true) {
        value = parseFloat(value);
        if (isNaN(value) && forceReturnNumber) {
            return 0;
        }
        return value.toFixed(decimal);
    }

    static getYearArray(baseYear = 1965): Array<number> {
        const years = [];
        const currentYear: any = moment(new Date()).format('YYYY');
        while (baseYear <= parseInt(currentYear) + 1) {
            years.push(baseYear);
            baseYear++;
        }
        return years;
    }

    static makeArrayFromNumber(number) {
        let arr = [];
        for (let i = 1; i <= parseInt(number); i++) {
            arr.push(i);
        }
        return arr;
    }

    static uniqByKeepLast(data, key) {
        return [
            // @ts-ignore
            ...new Map(data.map(x => [key(x), x])).values()
        ];
    }

    static removeDuplicates(arr) {
        return arr.filter((value, index) => arr.indexOf(value) === index);
    }

    static validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static validateContact(contact) {
        const re = /^[5-9]{1}[0-9]{9}$/;
        return re.test(String(contact));
    }

    static checkEqualArray(a, b) {
        if (a.length != b.length) {
            return false;
        } else {
            for (let i = 0; i < a.length; i++) {
                if (a[i] != b[i]) {
                    return false;
                }
            }
            return true;
        }
    }

    static repeatedObjects = (arr, key) => {
        let sorted_arr = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1][key] == sorted_arr[i][key]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    };

    static getMonthWeeks(month: number, year: number) {
        const days = [];
        const monetEndDate = moment().month(month - 1).year(year).endOf('month');
        let currentDate = moment(monetEndDate).startOf('month');
        while (currentDate.isSameOrBefore(monetEndDate)) {
            const startDate = moment(currentDate).startOf('isoWeek').set({hour: 0, minute: 0, second: 0, millisecond: 0});
            const endDate = moment(currentDate).endOf('isoWeek').set({hour: 23, minute: 59, second: 59, millisecond: 999});
            days.push({
                week: startDate.format('DD MMM') + '-' + endDate.format('DD MMM'),
                startDate: moment(currentDate).startOf('isoWeek'),
                endDate: moment(currentDate).endOf('isoWeek'),
                weekOfYear: startDate.week(),
                weekOfMonth: startDate.week()
            });
            currentDate = currentDate.endOf('isoWeek').add(1, 'day');
        }
        return days;
    }

    static isNumber(value): boolean {
        return !isNaN(value);
    }

    private static precedence(op) {
        if (op === '+' || op === '-') {
            return 1;
        }
        if (op === '*' || op === '/') {
            return 2;
        }
        return 0;
    }

    private static applyOp(a, b, op) {
        switch (op) {
            case '+':
                return a + b;
            case '-':
                return a - b;
            case '*':
                return a * b;
            case '/':
                return a / b;
        }
    }
}
