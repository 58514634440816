import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdmissionComponent} from "../admission/admission.component";
import {AdmissionFormComponent} from "./admission-form.component";

const routes: Routes = [
  {
    path: 'admission-form/:id',
    component: AdmissionFormComponent,
    data: {animation: 'admission'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmissionFormRoutingModule { }
