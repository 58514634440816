<div class="slideout-content">
    <ng-template #content>
        <div id="modal">
            <div class="modal-header font-weight-bold">
                <h4 class="modal-title font-weight-bold" *ngIf="followUpType === 'ADD'">Add Follow Up</h4>
                <div class="media align-items-center" *ngIf="followUpType === 'VIEW'">
                    <div class="avatar mr-50">
                        <img [src]="profileImage" alt="Avatar" width="38" height="38"/>
                    </div>
                    <div class="media-body">
                        <h6 class="mb-0 text-capitalize">
                            <span *ngIf="studentDetail && studentDetail['personal_info'] && studentDetail['personal_info'].full_name">{{studentDetail?.personal_info?.full_name.toLowerCase()}}</span>
                            <span *ngIf="studentDetail && studentDetail['name'] && (!studentDetail['personal_info'] || !studentDetail['personal_info'].full_name)">{{studentDetail['name'].toLowerCase()}}</span>
                            - Follow Ups
                        </h6>
                        <span class="mb-0">{{studentDetail?.university?.name}}</span>
                    </div>
                </div>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="modalService.dismissAll('')"
                >
                    ×
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="followUpType === 'ADD'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form mt-2 shadow-none" novalidate [formGroup]="addFollowUpForm">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="subject">Subject</label>
                                            <input class="form-control" id="subject" type="text"
                                                   formControlName="subject">
                                            <small *ngIf="submitted && addFollowUpForm.get('subject').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="stage">Stage</label>
                                            <select class="form-control" formControlName="stageId" id="stage"
                                                    (change)="getReason($event.target.value)">
                                                <option value="">Select Stage</option>
                                                <option [value]="stage.id"
                                                        *ngFor="let stage of stages">{{stage?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addFollowUpForm.get('stageId').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="reason">Reason</label>
                                            <select class="form-control" id="reason" formControlName="reasonId">
                                                <option value="">Select Reason</option>
                                                <option [value]="reason.id"
                                                        *ngFor="let reason of reasons">{{reason?.reason?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addFollowUpForm.get('reasonId').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="followUpType">Follow Up Type</label>
                                            <select class="form-control" id="followUpType"
                                                    formControlName="followUpType">
                                                <option value="">Select Follow Up Type</option>
                                                <option [value]="followUpType.value"
                                                        *ngFor="let followUpType of followUpTypes">{{followUpType?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addFollowUpForm.get('followUpType').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="followUpDateTime">Follow Up Date Time</label>
                                            <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
                                                   [min]="currentDateTime" placeholder="Date Time" id="followUpDateTime"
                                                   formControlName="followUpDateTime" class="form-control">
                                            <owl-date-time #dt1 [startAt]="currentDateTime"></owl-date-time>
                                            <small *ngIf="submitted && addFollowUpForm.get('followUpDateTime').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="remark">Remark</label>
                                            <textarea class="form-control resize-none" id="remark"
                                                      formControlName="remark" rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mt-1" *ngIf="followUpType === 'VIEW'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card card-user-timeline max-height-500px overflow-y-scroll" style="overflow-x: hidden">
                            <div class="card-body mb--10px">
                                <ul class="timeline ml-50 mb-0"
                                    *ngIf="followUps && followUps.length > 0; else noFollowUpToShow">
                                    <li class="timeline-item" *ngFor="let followUp of followUps; let i = index">
                                        <span class="timeline-point timeline-point-indicator"></span>
                                        <div class="timeline-event">
                                            <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                                <h6>{{followUp?.subject}}</h6>
                                                <span class="timeline-event-time mr-1">{{followUp?.datetime}}</span>
                                            </div>
                                            <p class="mb-0 text-italic">Stage - {{followUp?.stage?.name}}</p>
                                            <p class="mb-0 text-italic">Reason - {{followUp?.reason?.name}}</p>
                                            <p>
                                                <span *ngIf="followUp['type'] === 'CALL'">
                                                    <i class="fas fa-mobile-alt cursor-pointer text-primary mr-1"></i>
                                                </span>
                                                <span *ngIf="followUp['type'] === 'WHATS_APP'">
                                                    <i class="fab fa-whatsapp cursor-pointer text-success mr-1"></i>
                                                </span>
                                                <span *ngIf="followUp['type'] === 'SMS'">
                                                    <i class="fas fa-sms cursor-pointer text-primary"></i>
                                                </span>
                                                <span>{{followUp?.remark}}</span>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <ng-template #noFollowUpToShow>
                                    <ul class="timeline ml-50 mb-0 mt-8px">
                                        <li class="timeline-item">
                                            <span class="timeline-point timeline-point-indicator"></span>
                                            <div class="timeline-event">
                                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                                    <h6>No follow ups added</h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success color-white pull-right mt-1 mb-1" (click)="submitFollowUp()"
                        *ngIf="followUpType === 'ADD'">
                    <i class="fas fa-plus-circle mr-1"></i>Add
                </button>
                <button class="btn btn-danger color-white pull-right mt-1 mb-1" (click)="modalService.dismissAll('')"
                        *ngIf="followUpType === 'VIEW'">
                    <i class="fas fa-times-circle mr-1"></i>Close
                </button>
            </div>
        </div>
    </ng-template>
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Student Detail</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <div class="row">
                        <div class="col-12">
                            <div class="card-profile shadow-complete border-radius-4px text-inherit mt-2"
                                 style="text-align: inherit;">
                                <img src="assets/images/image-backgrounds/1600-1600.jpg" class="img-fluid card-img-top"
                                     alt="Profile Cover Photo"/>
                                <div class="card-body">
                                    <div class="profile-image-wrapper">
                                        <div class="profile-image">
                                            <div class="avatar profile-avatar bg-primary" *ngIf="!profileImage">
                                                <span>{{studentDetail?.name.charAt(0).toUpperCase()}}</span>
                                            </div>
                                            <div class="avatar" *ngIf="profileImage">
                                                <img [src]="profileImage" [alt]="profileImage.name"/>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="text-center text-capitalize">{{studentDetail?.name.toLowerCase()}}</h3>
                                    <h6 class="text-center text-muted">{{studentDetail?.username}}</h6>
                                    <h6 class="text-center text-muted">
                                        {{studentDetail?.university?.name}}
                                    </h6>
                                    <hr/>
                                    <small>Address</small>
                                    <h6 class="text-muted text-left"
                                        *ngIf="studentDetail && studentDetail['address']">{{studentDetail?.address?.address}}</h6>
                                    <h6 class="text-muted text-left"
                                        *ngIf="!studentDetail || !studentDetail['address'] || !studentDetail['address'].address">
                                        -</h6>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <small>Pin</small>
                                            <h6 class="text-muted text-left"
                                                *ngIf="studentDetail && studentDetail['address'] && studentDetail['address'].pin">{{studentDetail?.address?.pin}}</h6>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <small>State</small>
                                            <h6 class="text-muted text-left">{{studentDetail?.address?.state?.name}}</h6>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <small>Course</small>
                                            <h6 class="text-muted text-left"
                                                *ngIf="studentDetail && studentDetail.course">{{studentDetail?.course}}</h6>
                                            <!--<h6 class="text-muted text-left"
                                                *ngIf="studentDetail && studentDetail.register_course">{{studentDetail?.register_course}}</h6>-->
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <small>Branch</small>
                                            <h6 class="text-muted text-left">{{studentDetail?.branch}}</h6>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row" *ngIf="['UNIVERSITY'].indexOf(user['user_type']) === -1">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <small>Student Status</small>
                                            <div class="d-flex justify-content-between flex-wrap mt--15px">
                                                <div class="demo-inline-spacing">
                                                    <div class="btn-group text-capitalize">
                                                        <div ngbDropdown [placement]="'right-top'">
                                                            {{studentDetail?.status.toLowerCase()}}
                                                            <!--<button class="btn btn-sm"
                                                                    [ngClass]="{'btn-primary': (studentDetail && ((studentDetail['added_by'] === user['userId'] && (['COUNSELLOR'].indexOf(user['user_type']) !== -1)) || ((studentDetail['added_by'] !== user['userId'] &&
                                                                    (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))))),
                                                                    'btn-danger': (studentDetail && studentDetail['added_by'] === user['userId'] && (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))}">
                                                                {{studentDetail?.status}}
                                                            </button>-->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                             *ngIf="studentDetail && studentDetail['status_id'] === 2">
                                            <small>Follow Up(s)</small>
                                            <p class="mt-12px">
                                                <i class="fas fa-plus-circle f_20 text-primary cursor-pointer ml-1"
                                                   title="Add Follow Up" (click)="followUp('ADD', content)"></i>
                                                <i class="fas fa-cannabis f_20 text-primary cursor-pointer ml-6px"
                                                   title="View Previous Follow Up"
                                                   (click)="followUp('VIEW', content)"></i>
                                            </p>
                                        </div>
                                    </div>
                                    <!--<hr class="mb-2" *ngIf="['UNIVERSITY'].indexOf(user['user_type']) === -1"/>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Mail</h6>
                                            <h3 class="mb-0 text-center">
                                                &lt;!&ndash;<i class="fas fa-envelope cursor-pointer text-danger"></i>&ndash;&gt;
                                                <i class="fas fa-envelope cursor-pointer text-secondary"></i>
                                            </h3>
                                        </div>
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">WhatsApp</h6>
                                            <h3 class="mb-0 text-center">
                                                &lt;!&ndash;<i class="fab fa-whatsapp cursor-pointer text-success"></i>&ndash;&gt;
                                                <i class="fab fa-whatsapp cursor-pointer text-secondary"></i>
                                            </h3>
                                        </div>
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">SMS</h6>
                                            <h3 class="mb-0 text-center">
                                                &lt;!&ndash;<i class="fas fa-sms cursor-pointer text-primary"></i>&ndash;&gt;
                                                <i class="fas fa-sms cursor-pointer text-secondary"></i>
                                            </h3>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
