import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {StorageService} from '../../shared/services/storage.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {StudentService} from '../../shared/services/student.service';
import {AccountingService} from '../../shared/services/accounting.service';
import {Router} from '@angular/router';
import {AppConstants} from '../../shared/constants/app-constant';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
    studentSessionForm: FormGroup;
    user: any;
    settings: any = [];
    businessHeadCenterDetail: any;
    submitting: any = false;
    pendingStudents: any;
    processableStudents: any;
    processingStudents: any;
    processedStudents: any;
    totalCenter: any;
    centerPendingAmount: any;
    centerProcessableAmount: any;
    centerProcessedAmount: any;
    totalReceiptsAmount: any;
    pendingReceiptsAmount: any;
    acceptedReceiptsAmount: any;
    rejectedReceiptsAmount: any;
    centerProcessedAmountLoader: any = false;

    constructor(private fb: FormBuilder,
                private universitySettingService: UniversitySettingService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private studentService: StudentService,
                private accountingService: AccountingService,
                private router: Router) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') {
            this.getSettings();
        }
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getSettings();
        });
    }

    getSettings() {
        let universityId = (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') ? 10 : this.businessHeadCenterDetail['business_admin_detail']['university_id'];
        this.universitySettingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            this.getPendingStudents();
            this.getProcessableStudents();
            this.getProcessingStudents();
            this.getProcessedStudents();
            this.totalCenters();
            this.centersPendingAmount();
            this.centersProcessableAmount();
            this.getTotalReceiptsAmount();
            this.getPendingReceiptsAmount();
            this.getAcceptedReceiptsAmount();
            this.getRejectedReceiptsAmount();
            //this.centersProcessedAmount();
        }
    }

    changeSettings(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            this.getPendingStudents();
            this.getProcessableStudents();
            this.getProcessingStudents();
            this.getProcessedStudents();
            this.centersPendingAmount();
            this.centersProcessableAmount();
            this.getTotalReceiptsAmount();
            this.getPendingReceiptsAmount();
            this.getAcceptedReceiptsAmount();
            this.getRejectedReceiptsAmount();
            //this.centersProcessedAmount();
        }
    }

    getPendingStudents() {
        this.pendingStudents = null;
        this.submitting = true;
        this.accountingService.getStudentsPending({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.pendingStudents = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getProcessableStudents() {
        this.processableStudents = null;
        this.submitting = true;
        this.accountingService.getStudentsProcessable({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.processableStudents = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getProcessingStudents() {
        this.processingStudents = null;
        this.submitting = true;
        this.accountingService.getStudentsProcessing({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.processingStudents = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getProcessedStudents() {
        this.processedStudents = null;
        this.submitting = true;
        this.accountingService.getStudentsProcessed({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.processedStudents = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    totalCenters() {
        this.totalCenter = null;
        this.submitting = true;
        this.accountingService.getTotalCenters({}).subscribe(data => {
            this.totalCenter = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    centersPendingAmount() {
        this.centerPendingAmount = null;
        let params = {
            'admission_session': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : null,
            'page': -1
        };
        this.accountingService.getCentersPendingStudents(params).subscribe(dt => {
            let allData = [];
            if (dt && dt.length > 0) {
                dt.forEach(data => {
                    allData.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'name': (data && data['student_name']) ? data['student_name'] : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data && data['email']) ? data['email'] : '',
                        'phone': (data && data['contact']) ? data['contact'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'remarks': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['remarks']) ? data['student']['student_detail']['remarks'] : '',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'govt_photo_id_proof_error': (data && data['govt_photo_id_proof_error']) ? data['govt_photo_id_proof_error'] : '',
                        'tenth_certificate_error': (data && data['tenth_certificate_error']) ? data['tenth_certificate_error'] : '',
                        'twelth_certificate_error': (data && data['twelth_certificate_error']) ? data['twelth_certificate_error'] : '',
                        'graduate_certificate_error': (data && data['graduate_certificate_error']) ? data['graduate_certificate_error'] : '',
                        'student_document_download_loader': false
                    });
                });
            }
            if (allData && allData.length > 0) {
                let finalArr = [];
                allData.forEach(all => {
                    let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                    let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                    let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                    let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                    let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                    let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                    let chargeableCourseFee = 0;
                    let total = 0;
                    let reRegistrationPercentageActual = 0;
                    let admissionPercentageActual = 0;
                    let reRegistrationPercentageDisplay = 0;
                    let admissionPercentageDisplay = 0;
                    let prospectusFee = 0;
                    let serviceCharge = 0;
                    let examinationFee = 0;
                    if (all.status_id === 10) {
                        if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                        chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                        total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                        if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        }
                    } else {
                        if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                        chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                        total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                        if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        }
                    }
                    finalArr.push({
                        'id': all.id,
                        'name': all.name,
                        'photograph': all.photograph,
                        'pro_id': all.pro_id,
                        'enrollment_id': all.enrollment_id,
                        'roll_no': all.roll_no,
                        'username': all.username,
                        'email': all.email,
                        'phone': all.phone,
                        'program_id': all.program_id,
                        'course_id': all.course_id,
                        'branch_id': all.branch_id,
                        'program': all.program,
                        'course': all.course,
                        'branch': all.branch,
                        'status': all.status,
                        'remarks': all.remarks,
                        'process_status': all.process_status,
                        'status_id': all.status_id,
                        'transactions': all.transaction,
                        'register_course_id': all.register_course_id,
                        'register_course': all.register_course,
                        'student_year': all.student_year,
                        'added_by': all.added_by,
                        'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                        'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                        'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                        'pcb_mapping': all.pcb_mapping,
                        'total': total,
                        'counsellor': all.counsellor,
                        'head_counsellor': all.head_counsellor,
                        'business_center': all.business_center,
                        'father_name': all.father_name,
                        'mother_name': all.mother_name,
                        'gender': all.gender,
                        'date_of_birth': all.date_of_birth,
                        'chargeableCourseFee': chargeableCourseFee,
                        'govt_photo_id_proof_error': all.govt_photo_id_proof_error,
                        'tenth_certificate_error': all.tenth_certificate_error,
                        'twelth_certificate_error': all.twelth_certificate_error,
                        'graduate_certificate_error': all.graduate_certificate_error,
                        'student_document_download_loader': all.student_document_download_loader
                    });
                });
                let totalArr = [];
                if (finalArr && finalArr.length > 0) {
                    finalArr.forEach(final => {
                        if (final && final.total) {
                            totalArr.push(final.total);
                        }
                    });
                }
                this.centerPendingAmount = (totalArr && totalArr.length > 0) ? totalArr.reduce((a, b) => a + b) : 0;
            } else {
                this.centerPendingAmount = 0;
            }
        });
    }

    centersProcessableAmount() {
        this.centerProcessableAmount = null;
        let params = {
            'admission_session': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : null,
            'page': -1
        };
        this.accountingService.getCentersProcessableStudents(params).subscribe(dt => {
            let allData = [];
            if (dt && dt.length > 0) {
                dt.forEach(data => {
                    allData.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'name': (data && data['student_name']) ? data['student_name'] : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data && data['email']) ? data['email'] : '',
                        'phone': (data && data['contact']) ? data['contact'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'remarks': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['remarks']) ? data['student']['student_detail']['remarks'] : '',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'govt_photo_id_proof_error': (data && data['govt_photo_id_proof_error']) ? data['govt_photo_id_proof_error'] : '',
                        'tenth_certificate_error': (data && data['tenth_certificate_error']) ? data['tenth_certificate_error'] : '',
                        'twelth_certificate_error': (data && data['twelth_certificate_error']) ? data['twelth_certificate_error'] : '',
                        'graduate_certificate_error': (data && data['graduate_certificate_error']) ? data['graduate_certificate_error'] : '',
                        'student_document_download_loader': false
                    });
                });
            }
            if (allData && allData.length > 0) {
                let finalArr = [];
                allData.forEach(all => {
                    let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                    let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                    let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                    let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                    let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                    let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                    let chargeableCourseFee = 0;
                    let total = 0;
                    let reRegistrationPercentageActual = 0;
                    let admissionPercentageActual = 0;
                    let reRegistrationPercentageDisplay = 0;
                    let admissionPercentageDisplay = 0;
                    let prospectusFee = 0;
                    let serviceCharge = 0;
                    let examinationFee = 0;
                    if (all.status_id === 10) {
                        if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                        chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                        total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                        if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        }
                    } else {
                        if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                        chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                        total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                        if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        }
                    }
                    finalArr.push({
                        'id': all.id,
                        'name': all.name,
                        'photograph': all.photograph,
                        'pro_id': all.pro_id,
                        'enrollment_id': all.enrollment_id,
                        'roll_no': all.roll_no,
                        'username': all.username,
                        'email': all.email,
                        'phone': all.phone,
                        'program_id': all.program_id,
                        'course_id': all.course_id,
                        'branch_id': all.branch_id,
                        'program': all.program,
                        'course': all.course,
                        'branch': all.branch,
                        'status': all.status,
                        'remarks': all.remarks,
                        'process_status': all.process_status,
                        'status_id': all.status_id,
                        'transactions': all.transaction,
                        'register_course_id': all.register_course_id,
                        'register_course': all.register_course,
                        'student_year': all.student_year,
                        'added_by': all.added_by,
                        'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                        'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                        'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                        'pcb_mapping': all.pcb_mapping,
                        'total': total,
                        'counsellor': all.counsellor,
                        'head_counsellor': all.head_counsellor,
                        'business_center': all.business_center,
                        'father_name': all.father_name,
                        'mother_name': all.mother_name,
                        'gender': all.gender,
                        'date_of_birth': all.date_of_birth,
                        'chargeableCourseFee': chargeableCourseFee,
                        'govt_photo_id_proof_error': all.govt_photo_id_proof_error,
                        'tenth_certificate_error': all.tenth_certificate_error,
                        'twelth_certificate_error': all.twelth_certificate_error,
                        'graduate_certificate_error': all.graduate_certificate_error,
                        'student_document_download_loader': all.student_document_download_loader
                    });
                });
                let totalArr = [];
                if (finalArr && finalArr.length > 0) {
                    finalArr.forEach(final => {
                        if (final && final.total) {
                            totalArr.push(final.total);
                        }
                    });
                }
                this.centerProcessableAmount = (totalArr && totalArr.length > 0) ? totalArr.reduce((a, b) => a + b) : 0;
            } else {
                this.centerProcessableAmount = 0;
            }
        });
    }

    centersProcessedAmount() {
        this.centerProcessedAmount = null;
        this.centerProcessedAmountLoader = true;
        let params = {
            'admission_session': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : null,
            'page': -1
        };
        this.accountingService.getCentersProcessedStudents(params).subscribe(dt => {
            let allData = [];
            if (dt && dt.length > 0) {
                dt.forEach(data => {
                    allData.push({
                        'id': (data && data['student_id']) ? data['student_id'] : '',
                        'name': (data && data['student_name']) ? data['student_name'] : '',
                        'photograph': (data['photograph']) ? data['photograph'] : '',
                        'pro_id': (data && data['pro_id']) ? data['pro_id'] : '-',
                        'enrollment_id': (data && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'roll_no': (data && data['roll_no']) ? data['roll_no'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data && data['email']) ? data['email'] : '',
                        'phone': (data && data['contact']) ? data['contact'] : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status':
                            (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['status']) ?
                                ((data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_UPLOADED' ? 'DOCUMENTS UPLOADED' :
                                    (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_RE_UPLOADED' ? 'DOCUMENTS RE UPLOADED' :
                                        (data['student']['student_detail'] && data['student']['student_detail']['status'] === 'DOCUMENTS_REQUIRED' ? 'DOCUMENTS REQUIRED' :
                                            data['student']['student_detail']['status'].toLowerCase())))) :
                                '-',
                        'remarks': (data && data['student'] && data['student']['student_detail'] && data['student']['student_detail']['remarks']) ? data['student']['student_detail']['remarks'] : '',
                        'process_status': (data && data['processing_status']) ? data['processing_status'] : '',
                        'status_id': (data && data['admission_type'] && data['admission_type']['id']) ? data['admission_type']['id'] : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['added_by_business_head_center'] && data['added_by_business_head_center']) ? data['added_by_business_head_center'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'father_name': (data && data['father_name']) ? data['father_name'] : '',
                        'mother_name': (data && data['mother_name']) ? data['mother_name'] : '',
                        'gender': (data && data['gender']) ? data['gender'] : '',
                        'date_of_birth': (data && data['date_of_birth']) ? data['date_of_birth'] : '',
                        'govt_photo_id_proof_error': (data && data['govt_photo_id_proof_error']) ? data['govt_photo_id_proof_error'] : '',
                        'tenth_certificate_error': (data && data['tenth_certificate_error']) ? data['tenth_certificate_error'] : '',
                        'twelth_certificate_error': (data && data['twelth_certificate_error']) ? data['twelth_certificate_error'] : '',
                        'graduate_certificate_error': (data && data['graduate_certificate_error']) ? data['graduate_certificate_error'] : '',
                        'student_document_download_loader': false
                    });
                });
            }
            if (allData && allData.length > 0) {
                let finalArr = [];
                allData.forEach(all => {
                    let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                    let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                    let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                    let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                    let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                    let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                    let chargeableCourseFee = 0;
                    let total = 0;
                    let reRegistrationPercentageActual = 0;
                    let admissionPercentageActual = 0;
                    let reRegistrationPercentageDisplay = 0;
                    let admissionPercentageDisplay = 0;
                    let prospectusFee = 0;
                    let serviceCharge = 0;
                    let examinationFee = 0;
                    if (all.status_id === 10) {
                        if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && (this.user['user_type'] === 'ACCOUNTING')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(all['added_by']))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        }
                        chargeableCourseFee = re_registration_amount * ((reRegistrationPercentageDisplay) / 100);
                        total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + re_registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                        if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'ACCOUNTING')) {
                            if (all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] == AppConstants.MS_HEAD_CENTER_ID) {
                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                    }
                                });
                            } else {
                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                    }
                                });
                            }
                        }
                    } else {
                        if (all && all['business_center'] && all['business_center']['business_centers_share_percentage'] && all['business_center']['business_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && (parseInt(business_center_share_percentage['center_id']) === parseInt(all['added_by'].id))) {
                                    admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
                            all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year)) && ((parseInt(business_head_center_share_percentage['head_center_id']) === parseInt(this.user['userId'])))) {
                                    admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                    reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                    admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                    reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                    serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                    examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                }
                            });
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0 && (this.user['user_type'] === 'ACCOUNTING')) {
                            if (all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] && all['business_center']['business_center_detail']['business_head_center_detail']['user_id'] == AppConstants.MS_HEAD_CENTER_ID) {
                                all['business_center']['business_centers_share_percentage'].forEach(business_center_share_percentage => {
                                    if ((parseInt(business_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                        admissionPercentageDisplay = business_center_share_percentage['admission_percentage_display'];
                                        reRegistrationPercentageDisplay = business_center_share_percentage['re_registration_percentage_display'];
                                        admissionPercentageActual = business_center_share_percentage['admission_percentage_actual'];
                                        reRegistrationPercentageActual = business_center_share_percentage['re_registration_percentage_actual'];
                                        prospectusFee = parseInt(business_center_share_percentage['prospectus_fee']);
                                        serviceCharge = parseInt(business_center_share_percentage['service_charge']);
                                        examinationFee = parseInt(business_center_share_percentage['examination_fee']);
                                    }
                                });
                            } else {
                                all['business_center']['business_center_detail']['business_head_centers_share_percentage'].forEach(business_head_center_share_percentage => {
                                    if ((parseInt(business_head_center_share_percentage['setting_id']) === parseInt(this.studentSessionForm.value.student_session_year))) {
                                        admissionPercentageDisplay = business_head_center_share_percentage['admission_percentage_display'];
                                        reRegistrationPercentageDisplay = business_head_center_share_percentage['re_registration_percentage_display'];
                                        admissionPercentageActual = business_head_center_share_percentage['admission_percentage_actual'];
                                        reRegistrationPercentageActual = business_head_center_share_percentage['re_registration_percentage_actual'];
                                        prospectusFee = parseInt(business_head_center_share_percentage['prospectus_fee']);
                                        serviceCharge = parseInt(business_head_center_share_percentage['service_charge']);
                                        examinationFee = parseInt(business_head_center_share_percentage['examination_fee']);
                                    }
                                });
                            }
                        }
                        chargeableCourseFee = course_fee * ((admissionPercentageDisplay) / 100);
                        total = (!isNaN(prospectusFee) ? prospectusFee : prospectus_fee) + registration_amount + chargeableCourseFee + (!isNaN(serviceCharge) ? serviceCharge : service_charge) + (!isNaN(examinationFee) ? examinationFee : examination_fee);
                        if (all && all['business_center'] && all['business_center']['business_center_detail'] && (this.user['user_type'] === 'BUSINESS_ADMIN' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        } else if (all && all['business_center'] && all['business_center']['business_center_detail'] && all['business_center']['business_center_detail']['business_head_center_detail'] && all['business_center']['business_center_detail']['business_head_centers_share_percentage'] && (all['business_center']['business_center_detail']['business_head_centers_share_percentage'].length > 0) && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                            all['business_center']['business_center_detail']['admission_percentage_display'] = admissionPercentageDisplay;
                            all['business_center']['business_center_detail']['re_registration_percentage_display'] = reRegistrationPercentageDisplay;
                            all['business_center']['business_center_detail']['admission_percentage_actual'] = admissionPercentageActual;
                            all['business_center']['business_center_detail']['re_registration_percentage_actual'] = reRegistrationPercentageActual;
                            all['business_center']['business_center_detail']['prospectus_fee'] = prospectusFee;
                            all['business_center']['business_center_detail']['service_charge'] = serviceCharge;
                            all['business_center']['business_center_detail']['examination_fee'] = examinationFee;
                        }
                    }
                    finalArr.push({
                        'id': all.id,
                        'name': all.name,
                        'photograph': all.photograph,
                        'pro_id': all.pro_id,
                        'enrollment_id': all.enrollment_id,
                        'roll_no': all.roll_no,
                        'username': all.username,
                        'email': all.email,
                        'phone': all.phone,
                        'program_id': all.program_id,
                        'course_id': all.course_id,
                        'branch_id': all.branch_id,
                        'program': all.program,
                        'course': all.course,
                        'branch': all.branch,
                        'status': all.status,
                        'remarks': all.remarks,
                        'process_status': all.process_status,
                        'status_id': all.status_id,
                        'transactions': all.transaction,
                        'register_course_id': all.register_course_id,
                        'register_course': all.register_course,
                        'student_year': all.student_year,
                        'added_by': all.added_by,
                        'prospectus_fee': (!isNaN(prospectusFee) ? prospectusFee : (all && all['pcb_mapping'] && all['pcb_mapping']['prospectus_fee']) ? all['pcb_mapping']['prospectus_fee'] : '-'),
                        'service_charge': (!isNaN(serviceCharge) ? serviceCharge : (all && all['pcb_mapping'] && all['pcb_mapping']['service_charge']) ? all['pcb_mapping']['service_charge'] : '-'),
                        'examination_fee': (!isNaN(examinationFee) ? examinationFee : (all && all['pcb_mapping'] && all['pcb_mapping']['examination_fee']) ? all['pcb_mapping']['examination_fee'] : '-'),
                        'pcb_mapping': all.pcb_mapping,
                        'total': total,
                        'counsellor': all.counsellor,
                        'head_counsellor': all.head_counsellor,
                        'business_center': all.business_center,
                        'father_name': all.father_name,
                        'mother_name': all.mother_name,
                        'gender': all.gender,
                        'date_of_birth': all.date_of_birth,
                        'chargeableCourseFee': chargeableCourseFee,
                        'govt_photo_id_proof_error': all.govt_photo_id_proof_error,
                        'tenth_certificate_error': all.tenth_certificate_error,
                        'twelth_certificate_error': all.twelth_certificate_error,
                        'graduate_certificate_error': all.graduate_certificate_error,
                        'student_document_download_loader': all.student_document_download_loader
                    });
                });
                let totalArr = [];
                if (finalArr && finalArr.length > 0) {
                    finalArr.forEach(final => {
                        if (final && final.total) {
                            totalArr.push(final.total);
                        }
                    });
                }
                this.centerProcessedAmount = (totalArr && totalArr.length > 0) ? totalArr.reduce((a, b) => a + b) : 0;
                this.centerProcessedAmountLoader = false;
            } else {
                this.centerProcessedAmount = 0;
                this.centerProcessedAmountLoader = false;
            }
        }, error => {
            this.centerProcessedAmountLoader = false;
        });
    }

    getTotalReceiptsAmount() {
        this.totalReceiptsAmount = null;
        this.submitting = true;
        this.accountingService.getTotalReceiptsAmount({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.totalReceiptsAmount = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getPendingReceiptsAmount() {
        this.pendingReceiptsAmount = null;
        this.submitting = true;
        this.accountingService.getPendingReceiptsAmount({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.pendingReceiptsAmount = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getAcceptedReceiptsAmount() {
        this.acceptedReceiptsAmount = null;
        this.submitting = true;
        this.accountingService.getAcceptedReceiptsAmount({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.acceptedReceiptsAmount = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getRejectedReceiptsAmount() {
        this.rejectedReceiptsAmount = null;
        this.submitting = true;
        this.accountingService.getRejectedReceiptsAmount({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.rejectedReceiptsAmount = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    isNumber(val): boolean {
        return typeof val === 'number';
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
