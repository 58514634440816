import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessAdminSettingsComponent} from './business-admin-settings.component';

const routes: Routes = [
    {
        path: 'business-admin-settings',
        component: BusinessAdminSettingsComponent,
        data: {animation: 'business-admin-settings'}
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BusinessAdminSettingsRoutingModule {
}
