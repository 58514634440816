import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {CoreConfigService} from '../../../@core/services/config.service';
import {StateService} from '../../shared/services/state.service';
import {repeaterAnimation} from 'app/main/animations/form-repeater.animation';
import {ProgramService} from '../../shared/services/program.service';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';
import {AlertService} from '../../shared/services/alert.service';
import {UniversityService} from '../../shared/services/university.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StudentService} from '../../shared/services/student.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {AdminService} from '../../shared/services/admin.service';
import {CourseService} from '../../shared/services/course.service';
import {BranchService} from '../../shared/services/branch.service';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BusinessCenterService} from '../../shared/services/business-center.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {NotificationService} from '../../shared/services/notification.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [repeaterAnimation]
})
export class DashboardComponent implements OnInit, AfterViewInit {
    studentSessionForm: FormGroup;
    user: any;
    states: any = [];
    data: any;
    id: any;
    public coreConfig: any;
    programs = [];
    courses = [];
    branches = [];
    universities: any = [];
    headCounsellorData: any;
    counsellorData: any;
    admissionLink: any;
    registeredStudents: any = 0;
    admissionDone: any = 0;
    reRegistrationDone: any = 0;
    lateralEntryDone: any = 0;
    creditTransferDone: any = 0;
    inProgressStudents: any = 0;
    notConnectedStudents: any = 0;
    admins: any = [];
    programCount: any = 0;
    courseCount: any = 0;
    branchCount: any = 0;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    counsellors: any = [];
    headCounsellors: any = [];
    businessHeadCenters: any = [];
    studentSessionYears: any = [];
    counsellorsProgressReport: any = {
        'assigned_students': 0,
        'registration_done_students': 0,
        'admission_done_students': 0,
        'not_connected_students': 0
    };
    headCounsellorsProgressReport: any = {
        'assigned_students': 0,
        'registration_done_students': 0,
        'admission_done_students': 0,
        'not_connected_students': 0
    };
    adminsProgressReport: any = {
        'assigned_students': 0,
        'registration_done_students': 0,
        'admission_done_students': 0,
        'not_connected_students': 0
    };
    businessCenterDetail: any;
    businessHeadCenterDetail: any;
    businessAdminDetail: any;
    selectedSession: any;
    activeNotification: any;

    constructor(public _coreConfigService: CoreConfigService,
                public stateService: StateService,
                public modalService: NgbModal,
                private programService: ProgramService,
                private courseService: CourseService,
                private branchService: BranchService,
                private router: Router,
                private pcbMappingService: PcbMappingService,
                private alertService: AlertService,
                private universityService: UniversityService,
                public headCounsellorService: HeadCounsellorService,
                private counsellorService: CounsellorService,
                public adminService: AdminService,
                public studentService: StudentService,
                private universitySettingService: UniversitySettingService,
                private businessCenterService: BusinessCenterService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService,
                private notificationService: NotificationService,
                private fb: FormBuilder) {
        this.user = StorageService.getItem('self');
    }


    ngOnInit(): void {
        this.studentSessionForm = this.fb.group({
            'university_id': [''],
            'student_session_year': ['']
        });
        if (this.user && (this.user['user_type'] === 'BUSINESS_CENTER' || this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
            this.getActiveNotification();
        }
        this.getStudentSession();
        /*this.getCounsellorDetails();
        this.getHeadCounsellorDetails();
        if (this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN')) {
            this.getHeadCounsellors();
        }

        if (this.user && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR' || this.user['user_type'] === 'COUNSELLOR')) {
            this.getUniversities();
        }*/
    }

    ngAfterViewInit(): void {
        this.refresh();
    }

    refresh() {
        this.getStates();
        //this.getUniversities(this.data);
        //this.getStudentCount();
        //this.getAdmissionDoneCount();
        //this.getInProgressDoneCount();
        //this.getNotConnectedCount();

        if (this.user && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ACCOUNTING')) {
            this.getAdmins();
            this.getHeadCounsellors();
            this.getUniversities();
        } else if (this.user && this.user['user_type'] === 'UNIVERSITY') {
            this.getProgramsCount();
            this.getCoursesCount();
            this.getBranchesCount();
            this.getStudentSessionData(this.user['userId']);
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getCounsellors();
            this.getAdmins();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
            this.getBusinessAdminDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.getBusinessCenterDetail();
        }
    }

    getBusinessCenterDetail() {
        this.businessCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessCenterDetail = data;
            this.getStudentSession();
        });
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getStudentSession();
        });
    }

    /*getBusinessHeadCenter() {
        this.business_head_centers = [];
        let admin = StorageService.getItem('self');
        this.businessHeadcenterService.get(admin['userId'], {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.business_head_centers = data['data'];
            } else {
                this.business_head_centers = [];
            }
            this.pagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
        });
    }*/

    getBusinessAdminDetail() {
        this.businessAdminService.detailById(this.user['userId']).subscribe(data => {
            this.businessAdminDetail = data;
            this.getStudentSession();
        });
    }

    getProgramsCount() {
        this.programService.count().subscribe(data => {
            if (data) {
                this.programCount = data;
            }
        }, error => {
            this.programCount = 0;
        });
    }

    getCoursesCount() {
        this.courseService.count().subscribe(data => {
            if (data) {
                this.courseCount = data;
            }
        }, error => {
            this.courseCount = 0;
        });
    }

    getBranchesCount() {
        this.branchService.count().subscribe(data => {
            if (data) {
                this.branchCount = data;
            }
        }, error => {
            this.branchCount = 0;
        });
    }

    getHeadCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.headCounsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.headCounsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.headCounsellors = [];
            }
        });
    }

    /* getbusinessHeadCenters() {
         /!*params.page = this.pagination.page;
         params.per_page = this.pagination.perpage;*!/
         this.businessHeadCenters = [];
         this.businessHeadCenterService.detailById(this.id).subscribe(data => {
             if (data && data['data'].length > 0) {
                 this.businessHeadCenters = data['data'];
                 /!*this.pagination = {
                     page: data.current_page,
                     total: data.total,
                     perpage: data.per_page,
                     pages: data.last_page,
                 };*!/
             } else {
                 this.businessHeadCenters = [];
             }
         });
     }*/

    getAdmins(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.admins = [];
        this.businessAdminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
        });
    }

    getUniversities() {
        this.universities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data['data'] && data['data'].length > 0) {
                this.universities = data['data'];
                this.studentSessionForm.patchValue({
                    'university_id': this.universities[this.universities.length - 1].id
                });
                this.getStudentSessionData(this.universities[this.universities.length - 1].id);
            } else {
                this.universities = [];
            }
        });
    }

    getStudentCount() {
        this.studentService.count().subscribe(data => {
            if (data) {
                this.registeredStudents = data;
            }
        }, error => {
            this.registeredStudents = 0;
        });
    }

    getAdmissionDoneCount(data) {
        this.studentService.admissionCount(data).subscribe(data => {
            this.admissionDone = data;
        }, error => {
            this.admissionDone = 0;
        });
    }

    getReRegisterCount(data) {
        this.studentService.reRegisterCount(data).subscribe(data => {
            this.reRegistrationDone = data;
        }, error => {
            this.reRegistrationDone = 0;
        });
    }

    getLateralEntryCount(data) {
        this.studentService.lateralCount(data).subscribe(data => {
            this.lateralEntryDone = data;
        }, error => {
            this.lateralEntryDone = 0;
        });
    }

    getCreditTransferCount(data) {
        this.studentService.creditTransferCount(data).subscribe(data => {
            this.creditTransferDone = data;
        }, error => {
            this.creditTransferDone = 0;
        });
    }

    getInProgressDoneCount() {
        this.studentService.inProgressCount().subscribe(data => {
            this.inProgressStudents = data;
        }, error => {
            this.inProgressStudents = 0;
        });
    }

    getNotConnectedCount() {
        this.studentService.getNotConnectedCount().subscribe(data => {
            this.notConnectedStudents = data;
        }, error => {
            this.notConnectedStudents = 0;
        });
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    changeStudentUniversity(event) {
        if (event && event.target && event.target.value) {
            this.getStudentSessionData(event.target.value);
        }
    }

    changeStudentSession(event) {
        if (event && event.target && event.target.value && this.studentSessionYears && this.studentSessionYears.length > 0) {
            let selectedSession = {};
            this.studentSessionYears.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSession = session;
                }
            });
            this.selectedSession = selectedSession;
            this.getAdmissionDoneCount({'session_id': this.selectedSession.id});
            this.getReRegisterCount({'session_id': this.selectedSession.id});
            this.getLateralEntryCount({'session_id': this.selectedSession.id});
            this.getCreditTransferCount({'session_id': this.selectedSession.id});
        }
    }

    getHeadCounsellorDetails() {
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.headCounsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.headCounsellorData = data;
                }
            });
        }
    }

    getCounsellorDetails() {
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'COUNSELLOR') {
            this.counsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.counsellorData = data;
                    if (this.counsellorData && this.counsellorData['counsellor_detail'] && this.counsellorData['counsellor_detail']['head_counsellor'] && this.counsellorData['counsellor_detail']['head_counsellor'].unique_link_status && this.counsellorData['counsellor_detail']['head_counsellor'].unique_link_status === 'ACTIVE') {
                        this.admissionLink = (this.counsellorData && this.counsellorData['counsellor_universities'] && this.counsellorData['counsellor_universities'][0] && this.counsellorData['counsellor_universities'][0]['university_detail'] && this.counsellorData['counsellor_universities'][0]['university_detail'].url) ? this.counsellorData['counsellor_universities'][0]['university_detail'].url + '?counsellor=' + this.counsellorData['counsellor_detail'].unique_id : '';
                    }
                }
            });
        }
    }

    openShareLink(content) {
        this.modalService.open(content, {
            centered: true,
            size: 'md'
        });
    }

    copyMessage() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.admissionLink;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.alertService.showSuccess({'title': 'success', 'message': 'Link Copied successfully'});
    }

    navigateTo(url) {
        window.open(url, 'blank');
    }

    getCounsellors() {
        this.counsellorService.get(this.data).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
            }
        });
    }

    /*getCounsellorReport(id) {
        this.counsellorService.getCounsellorReportCount(id).subscribe(data => {
            this.counsellorsProgressReport = data['data'];
        }, error => {
            this.counsellorsProgressReport = 0;
        });
    }*/

    getHeadCounsellorReport(id) {
        this.headCounsellorService.getHeadCounsellorReportCount(id).subscribe(data => {
            this.headCounsellorsProgressReport = data;
        });
    }

    getAdminReport(id) {
        this.adminService.getAdminReportCount(id).subscribe(data => {
            this.adminsProgressReport = data;
        });
    }

    /*getAdminReport(id) {
        this.businessAdminService.getAdminReportCount(id).subscribe(data => {
            this.adminsProgressReport = data;
        });
    }*/

    getCounsellorReport(id) {
        this.counsellorService.getCounsellorReportCount(id).subscribe(data => {
            this.counsellorsProgressReport = data;
        });
    }

    getStudentSession() {
        if (this.businessCenterDetail && this.businessCenterDetail['business_admin_detail'] && this.businessCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessHeadCenterDetail && this.businessHeadCenterDetail['business_admin_detail'] && this.businessHeadCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessHeadCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessAdminDetail && this.businessAdminDetail['university_id']) {
            this.getStudentSessionData(this.businessAdminDetail['university_id']);
        }
    }

    getActiveNotification() {
        this.notificationService.getActiveNotification({'page': -1}).subscribe(data => {
            if (data) {
                this.activeNotification = data['notification'];
            }
        });
    }

    getStudentSessionData(universityId) {
        this.universitySettingService.getByAdmin(universityId, {}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentSessionYears = data['data'];
                if (this.studentSessionYears && this.studentSessionYears.length > 0) {
                    let sessionYear = {};
                    this.studentSessionYears.forEach(session => {
                        if (session && session['session_default'] === 'ACTIVE') {
                            sessionYear = session;
                        }
                    });
                    this.patchFormSession(universityId, sessionYear);
                }
            } else {
                this.studentSessionYears = [];
                this.studentSessionForm.patchValue({
                    'student_session_year': ''
                });
                this.admissionDone = 0;
                this.reRegistrationDone = 0;
                this.lateralEntryDone = 0;
                this.creditTransferDone = 0;
            }
        });
    }

    patchFormSession(universityId, data) {
        this.studentSessionForm.patchValue({
            'student_session_year': (data && data.id) ? data.id : ''
        });
        this.selectedSession = data;
        this.getAdmissionDoneCount({'session_id': data.id});
        this.getReRegisterCount({'session_id': data.id});
        this.getLateralEntryCount({'session_id': data.id});
        this.getCreditTransferCount({'session_id': data.id});
    }
}
