import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NotificationService} from '../../shared/services/notification.service';
import {AlertService} from '../../shared/services/alert.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    notifications = [];
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private notificationService: NotificationService,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-notification').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 50, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.notifications = [];
        this.notificationService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.notifications = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.notifications = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        this.get();
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    delete(data) {
        if (data && data['status'] === 'INACTIVE') {
            this.notificationService.delete(data.id).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.get();
            });
        } else {
            this.alertService.showErrors('Please In Active the status before deletion');
        }
    }

    updateStatus(data, event) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': (event) ? 'ACTIVE' : 'INACTIVE'
            };
            this.submitting = true;
            this.notificationService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }
}
