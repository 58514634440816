import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessCenterService} from '../../shared/services/business-center.service';
import {StorageService} from '../../shared/services/storage.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {StudentService} from '../../shared/services/student.service';

@Component({
    selector: 'app-business-admin-centers',
    templateUrl: './business-admin-centers.component.html',
    styleUrls: ['./business-admin-centers.component.scss']
})
export class BusinessAdminCentersComponent implements OnInit {
    showSearch = false;
    id: any;
    data: any;
    selectedSession: any;
    updatePasswordForm: FormGroup;
    transferCenterForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    modalType: any;
    businessCenters: any = [];
    universities: any = [];
    contentHeader: object;
    user: any;
    adminId: any;
    headCenters = [];

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private studentService: StudentService,
                private businessCenterService: BusinessCenterService,
                private businessAdminService: BusinessAdminService,
                private businessHeadCenterService: BusinessHeadCenterService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        if (this.user && this.user['user_type'] !== 'BUSINESS_ADMIN') {
            this.router.navigateByUrl('dashboard');
        }
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.transferCenterForm = this.fb.group({
            'head_center_id': ['']
        });
        this.searchForm = this.fb.group({
            'code': ['']
        });
        this.getHeadCenters();
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-business-admin-center').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 50, pages: null});
            this.toggleSidebar();
        }
    }

    updatePasswordModal(content, id, type) {
        this.id = id;
        this.modalType = type;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.businessCenters = [];
        this.businessAdminService.getAdminBusinessCenters(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.businessCenters = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.businessCenters = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getHeadCenters() {
        this.businessHeadCenterService.get(this.user['userId'], {'page': -1}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.headCenters = data['data'];
            } else {
                this.headCenters = [];
            }
        }, error => {
            this.headCenters = [];
        });
    }

    search() {
        const params = {
            'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.businessCenterService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    transferCenter() {
        this.submitted = true;
        if (!this.transferCenterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'head_center_id': (this.transferCenterForm.value && this.transferCenterForm.value.head_center_id) ? this.transferCenterForm.value.head_center_id : ''
            };
            this.submitting = true;
            this.businessCenterService.transferCenter(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.transferCenterForm.patchValue({
                    'head_center_id': '',
                });
                this.get();
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'code': (this.searchForm && this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    transferCenterModal(content, id, type) {
        this.id = id;
        this.modalType = type;
        this.transferCenterForm.patchValue({
            'head_center_id': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.businessCenterService.updateStatus(data['self'].id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    sharePercentage(data: any) {
        this.router.navigateByUrl('business-head-center/' + data['business_head_center_detail']['user_id'] + '/business-center/' + data['self']['id'] + '/business-center-share-percentage');
    }

    downloadExcel() {
        this.businessCenterService.downloadCenterExcel().subscribe(data => {
            if (data && data['url']) {
                window.open(data['url'], '_blank');
            }
        });
    }
}
