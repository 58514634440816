import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class BusinessHeadCenterService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(adminId, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.BUSINESS_HEAD_CENTERS(adminId), data);
    }

    getAll(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.BUSINESS_HEAD_CENTERS_ALL(), data);
    }

    /*getHeadCenterAdmin(head_center_id): Observable<any> {
        return this.getRequest(AppUrl.BUSINESS_HEAD_CENTER_ADMIN(head_center_id));
    }*/

    getBusinessHeadCentersReport(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.GET_BUSINESS_HEAD_CENTERS_REPORT(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.BUSINESS_HEAD_CENTERS_COUNT());
    }

    detail(adminId, id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.BUSINESS_HEAD_CENTER_DETAIL(adminId, id));
    }

    detailById(id): Observable<any> {
        return this.getRequest(AppUrl.BUSINESS_HEAD_CENTER_DETAIL_BY_ID(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.BUSINESS_HEAD_CENTERS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTERS(id), data);
    }

    updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTER_PASSWORD(id), data);
    }

    updateHeadCenterAdditionStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTER_ADDITION_STATUS(id), data);
    }

    updateProfile(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTER_PROFILE_IMAGE(id), data);
    }

    updateWallet(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTER_WALLET_AMOUNT(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTER_STATUS(id), data);
    }

    getSharePercentage(data): Observable<any> {
        return this.getRequest(AppUrl.BUSINESS_HEAD_CENTER_SHARE_PERCENTAGE(), data);
    }

    createSharePercentage(data): Observable<any> {
        return this.postRequest(AppUrl.BUSINESS_HEAD_CENTER_SHARE_PERCENTAGE(), data);
    }

    updateSharePercentage(id, data): Observable<any> {
        return this.putRequest(AppUrl.BUSINESS_HEAD_CENTER_SHARE_PERCENTAGE(id), data);
    }

    downloadHeadCenterExcel() {
        return this.postRequest(AppUrl.BUSINESS_HEAD_CENTER_PERCENTAGE_DOWNLOAD());
    }

    downloadExcel(data) {
        return this.postRequest(AppUrl.BUSINESS_HEAD_CENTER_SHARE_PERCENTAGE_DOWNLOAD(), data);
    }

    downloadCenterReportExcel(data) {
        return this.postRequest(AppUrl.BUSINESS_CENTER_REPORT_DOWNLOAD(), data);
    }

    downloadHeadCenterReport(data) {
        return this.postRequest(AppUrl.BUSINESS_HEAD_CENTER_REPORT_DOWNLOAD(), data);
    }
}
