<div class="content-wrapper">
    <div class="content-body">
        <section id="settings">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="mt-5px">Back Session Entry</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card mt-2 p-2" *ngIf="business_head_centers && business_head_centers.length > 0">
                        <div class="row" *ngFor="let business_head_center of business_head_centers; let i = index">
                            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                <h6 class="mt-5px">
                                    <span class="mr-1">{{i + 1}}.</span>
                                    <span class="mr-1">
                                        <input [id]="i" type="checkbox"
                                               [checked]="(business_head_center && business_head_center['selected']) ? business_head_center['selected'] : false"
                                               (click)="selectHeadCenter(business_head_center)"/>
                                    </span>
                                    <span>{{business_head_center?.name}}</span>
                                </h6>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <i class="fas fa-chevron-circle-down mt-5px pull-right f_20 cursor-pointer"
                                   (click)="getUniversityCenter(business_head_center)"></i>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light"
                                 *ngIf="business_head_center && business_head_center['business_centers'] && business_head_center['business_centers'].length > 0">
                                <div class="row"
                                     *ngFor="let business_center of business_head_center['business_centers']; let j = index">
                                    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-10 mt-1">
                                        <span class="mr-1">
                                            <input [id]="j" type="checkbox"
                                                   [checked]="(business_center && business_center['selected']) ? business_center['selected'] : false"
                                                   (click)="selectCenter(business_center)"/>
                                        </span>
                                        <span>{{business_center?.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
