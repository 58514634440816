import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AccountingRoutingModule} from './accounting-routing.module';
import {AccountingComponent} from './accounting.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbNavModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { ReceiptsComponent } from './receipts/receipts.component';
import { ProcessStudentsComponent } from './process-students/process-students.component';
import {SharedModule} from '../../shared/shared.module';
import { CreditTransactionsComponent } from './credit-transactions/credit-transactions.component';

@NgModule({
    declarations: [AccountingComponent, ReceiptsComponent, ProcessStudentsComponent, CreditTransactionsComponent],
    imports: [
        CommonModule,
        AccountingRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgbNavModule,
        SharedModule
    ],
    exports: [AccountingComponent]
})

export class AccountingModule {
}
