import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotificationsComponent} from './notifications.component';
import {AddNotificationComponent} from './add-notification/add-notification.component';

const routes: Routes = [
    {
        path: 'notifications',
        component: NotificationsComponent,
        data: {animation: 'notifications'}
    },
    {
        path: 'notification/add',
        component: AddNotificationComponent,
        data: {animation: 'notification/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class NotificationsRoutingModule {
}
