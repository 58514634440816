import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReasonsComponent} from './reasons.component';
import {AddReasonComponent} from './add-reason/add-reason.component';

const routes: Routes = [
    {
        path: 'reasons',
        component: ReasonsComponent,
        data: {animation: 'reasons'}
    },
    {
        path: 'reason/add',
        component: AddReasonComponent,
        data: {animation: 'reason/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReasonsRoutingModule {
}
