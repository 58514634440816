import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class AccountingService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    getTransactionTotal(data): Observable<any> {
        return this.getRequest(AppUrl.TRANSACTION_TOTAL(), data);
    }

    resendOTP(data): Observable<any> {
        return this.getRequest(AppUrl.RESEND_OTP_ACCOUNTING(), data);
    }

    getReceiptTotal(data): Observable<any> {
        return this.getRequest(AppUrl.RECEIPT_TOTAL(), data);
    }

    getReceiptAccepeted(data): Observable<any> {
        return this.getRequest(AppUrl.RECEIPT_ACCEPTED(), data);
    }

    getReceiptPending(data): Observable<any> {
        return this.getRequest(AppUrl.RECEIPT_PENDING(), data);
    }

    getTotalStudent(data): Observable<any> {
        return this.getRequest(AppUrl.STUDENT_TOTAL(), data);
    }

    getStudentsPending(data): Observable<any> {
        return this.getRequest(AppUrl.GET_PENDING_STUDENTS(), data);
    }

    getStudentsProcessable(data): Observable<any> {
        return this.getRequest(AppUrl.GET_PROCESSABLE_STUDENTS(), data);
    }

    getStudentsProcessing(data): Observable<any> {
        return this.getRequest(AppUrl.GET_PROCESSING_STUDENTS(), data);
    }

    getStudentsProcessed(data): Observable<any> {
        return this.getRequest(AppUrl.GET_PROCESSED_STUDENTS(), data);
    }

    verifyAccountingUser(data): Observable<any> {
        return this.getRequest(AppUrl.VERIFY_ACCOUNTING_USER(), data);
    }

    accoutingOtp(data): Observable<any> {
        return this.postRequest(AppUrl.ACCOUNTING_OTP(), data);
    }

    getTotalCenters(data): Observable<any> {
        return this.getRequest(AppUrl.GET_TOTAL_CENTERS(), data);
    }

    getCentersPendingStudents(data): Observable<any> {
        return this.getRequest(AppUrl.GET_CENTERS_PENDING_STUDENTS(), data);
    }

    getCentersProcessableStudents(data): Observable<any> {
        return this.getRequest(AppUrl.GET_CENTERS_PROCESSABLE_STUDENTS(), data);
    }

    getCentersProcessedStudents(data): Observable<any> {
        return this.getRequest(AppUrl.GET_CENTERS_PROCESSED_STUDENTS(), data);
    }

    getTotalReceiptsAmount(data): Observable<any> {
        return this.getRequest(AppUrl.GET_TOTAL_RECIPTS_AMOUNT(), data);
    }

    getPendingReceiptsAmount(data): Observable<any> {
        return this.getRequest(AppUrl.GET_PENDING_RECIPTS_AMOUNT(), data);
    }

    getAcceptedReceiptsAmount(data): Observable<any> {
        return this.getRequest(AppUrl.GET_ACCEPTED_RECIPTS_AMOUNT(), data);
    }

    getRejectedReceiptsAmount(data): Observable<any> {
        return this.getRequest(AppUrl.GET_REJECTED_RECIPTS_AMOUNT(), data);
    }
}
