<div class="content-wrapper">
    <div class="content-body">
        <section id="students">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="removeEntryByAdminData">Mark as Processable
                            - {{removeEntryByAdminData?.username}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="selectedErrorRemark">Error Remark
                            - {{selectedErrorRemark?.username}}</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="removeEntryByAdminData">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="markProcessableForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="processing_remark">Remark</label>
                                                    <textarea class="form-control resize-none" id="processing_remark"
                                                              formControlName="processing_remark"></textarea>
                                                    <small *ngIf="submitted && markProcessableForm.get('processing_remark').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row" *ngIf="selectedErrorRemark">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h6 class="p-1">{{selectedErrorRemark?.processing_remark}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="updateRemark()" *ngIf="removeEntryByAdminData">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                        <button class="btn btn-danger color-white pull-right" [disabled]="submitting"
                                (click)="this.modalService.dismissAll('')" *ngIf="selectedErrorRemark">
                            Close
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <h2 class="mt-5px">Processs Students</h2>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Student" (click)="goToStudents()"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-user-graduate color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Accounting" (click)="goToAccounting()"
                            *ngIf="user && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-calculator color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Excel" (click)="downloadExcel('ACTUAL')"
                            *ngIf="user && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Search" (click)="showSearch()"
                            *ngIf="user && ['SUPER_ADMIN', 'UNIVERSITY', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-search color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-success pull-right ml-6px" rippleEffect
                            title="Process Students" (click)="processNow()"
                            *ngIf="user && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && activeType === 'PROCESSABLE'">
                        <i class="fas fa-tasks color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-danger pull-right ml-6px" rippleEffect
                            title="Un Process Students" (click)="processableNow()"
                            *ngIf="user && ['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && activeType === 'PROCESSING'">
                        <i class="fas fa-tasks color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-success pull-right ml-6px" rippleEffect
                            title="Processed Students" (click)="processedNow()"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && activeType === 'PROCESSING'">
                        <i class="fas fa-tasks color-white f_20 cursor-pointer"></i>
                    </button>
                </div>
            </div>
            <div class="row mb-1 mt-2">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div role="group" aria-label="Basic example" class="btn-group">
                        <button type="button" class="btn btn-outline-primary waves-effect"
                                [ngClass]="{'active': (activeType === 'PROCESSABLE')}"
                                (click)="getProcessStudents({'processing_status': 'PROCESSABLE'})">
                            Processable
                        </button>
                        <button type="button" class="btn btn-outline-primary waves-effect"
                                [ngClass]="{'active': (activeType === 'PROCESSING')}"
                                (click)="getProcessStudents({'processing_status': 'PROCESSING'})">
                            Processing
                        </button>
                        <button type="button" class="btn btn-outline-primary waves-effect"
                                [ngClass]="{'active': (activeType === 'PROCESSED')}"
                                (click)="getProcessStudents({'processing_status': 'PROCESSED'})">
                            Processed
                        </button>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <h4 class="mt-10px"
                        *ngIf="activeType === 'PROCESSABLE' && user && (user['user_type'] === 'BUSINESS_HEAD_CENTER' || user['user_type'] === 'BUSINESS_CENTER')">
                        Processable Credits :
                        &#8377; {{processableCredits | IndianNumber}}</h4>
                    <!--<h4 class="mt-10px" *ngIf="activeType === 'PROCESSING'">Processing Credits :
                        &#8377; {{processingCredits | IndianNumber}}</h4>
                    <h4 class="mt-10px" *ngIf="activeType === 'PROCESSED'">Processed Credits :
                        &#8377; {{processedCredits | IndianNumber}}</h4>-->
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <select class="form-control" formControlName="university_id"
                                    (change)="changeStudentUniversity($event)"
                                    *ngIf="['SUPER_ADMIN'].indexOf(user['user_type']) !== -1">
                                <option class="text-center" [value]="university.id"
                                        *ngFor=" let university of universities">{{university?.name}}</option>
                            </select>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeStudentSession($event)">
                                <option class="text-center" [value]="studentSessionYear.id"
                                        *ngFor=" let studentSessionYear of studentSessionYears">{{studentSessionYear?.session_name}}
                                    - {{studentSessionYear?.session_year}}</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card" *ngIf="openSearch">
                <div class="card-body">
                    <form class="row text-center" novalidate [formGroup]="searchForm">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"/>
                            <small>Name</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Application Number"
                                   formControlName="application_number"/>
                            <small>Application Id</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Email" formControlName="email"/>
                            <small>Email</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Phone" formControlName="contact"/>
                            <small>Phone</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Pro Id" formControlName="pro_id"/>
                            <small>Pro Id</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Enrollment Id"
                                   formControlName="enrollment_id"/>
                            <small>Enrollment Id</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Roll No" formControlName="roll_no"/>
                            <small>Roll No.</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="course_id">
                                <option value="">Select Course</option>
                                <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
                            </select>
                            <small>Course</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['BUSINESS_ADMIN', 'SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="added_by_business_head_center"
                                    (change)="getBusinessCenter($event)">
                                <option value="">Select Head Center</option>
                                <option [value]="headCenter.id"
                                        *ngFor="let headCenter of headCenters">{{headCenter?.name}}</option>
                            </select>
                            <small>Business Head Center</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['BUSINESS_ADMIN', 'SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="added_by">
                                <option value="">Select Center</option>
                                <option [value]="businessCenter['business_center_detail']['user_id']"
                                        *ngFor="let businessCenter of businessCenters">{{businessCenter?.business_center_detail?.code}}
                                    - {{businessCenter?.name}}</option>
                            </select>
                            <small>Business Center</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <button class="btn btn-primary btn-block" (click)="search()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill" [(activeId)]="active">
                        <li ngbNavItem [ngbNavItem]="i" *ngFor="let tab of tabs; let i = index">
                            <a ngbNavLink (click)="getTabData(tab, i)">{{tab?.name}}</a>
                            <ng-template ngbNavContent>
                                <div class="card-body">
                                    <div class="row overflow-x-scroll overflow-table">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <table class="table table-list text-center shadow-complete">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Head Center</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Center</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Application Id</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Pro Id</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Enrollment Id</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Roll No.</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Photograph</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Father's Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Mother's Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Phone</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Admission Actual (%)</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration Actual
                                                            (%)</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Admission <span
                                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                            (%)</h6>
                                                    </th>
                                                    <th class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration <span
                                                                *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">Display</span>
                                                            (%)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Prospectus Fee
                                                            (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Registration Fee (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Course Fee (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Service Fee
                                                            (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Examination Fee
                                                            (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration Fee (&#8377;)</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Total Fee (&#8377;)</h6>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody *ngIf="tab && tab['data'] && tab['data'].length > 0; else noDataToShowWeb">
                                                <tr *ngFor="let data of tab['data']; let i = index"
                                                    [ngClass]="{
                                                        'background-light-red': (data && (!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')),
                                                        'background-light-yellow': (data && data['processing_remark'])
                                                    }">
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{(studentPagination.page - 1) * (studentPagination.perpage ? studentPagination.perpage : 0) + i + 1}}
                                                            .</h6>
                                                    </td>
                                                    <td class="text-center" style="min-width: 200px">
                                                        <i aria-hidden="true"
                                                           class="fas fa-check-circle f_20 text-secondary cursor-pointer mt-1 mr-1"
                                                           (click)="chooseEntry(data)"
                                                           title="Choose to process"
                                                           *ngIf="(['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1) && (processingStudents.indexOf(data.id) === -1) && data['processing_status'] === 'PROCESSABLE'"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-check-circle f_20 text-success cursor-pointer mt-1 mr-1"
                                                           (click)="chooseEntry(data)"
                                                           title="Choosen to process"
                                                           *ngIf="(['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1) && (processingStudents.indexOf(data.id) !== -1) && data['processing_status'] === 'PROCESSABLE'"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-times-circle f_20 text-secondary cursor-pointer mt-1 mr-1"
                                                           (click)="removeEntry(data)"
                                                           title="Choose to process"
                                                           *ngIf="(['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1) && (processableStudents.indexOf(data.id) === -1) && data['processing_status'] === 'PROCESSING'"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-times-circle f_20 text-danger cursor-pointer mt-1 mr-1"
                                                           (click)="removeEntry(data)"
                                                           title="Choosen to process"
                                                           *ngIf="(['BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1) && (processableStudents.indexOf(data.id) !== -1) && data['processing_status'] === 'PROCESSING'"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-times-circle f_20 text-danger cursor-pointer mt-1 mr-1"
                                                           (click)="removeEntryByAdmin(content, data)"
                                                           title="Choosen to process"
                                                           *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1) && data['processing_status'] === 'PROCESSING'"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-check-circle f_20 text-secondary cursor-pointer mt-1 mr-1"
                                                           (click)="processedEntry(data)"
                                                           title="Choose to process"
                                                           *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1) && (processedStudents.indexOf(data.id) === -1) && data['processing_status'] === 'PROCESSING'"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-check-circle f_20 text-success cursor-pointer mt-1 mr-1"
                                                           (click)="processedEntry(data)"
                                                           title="Choosen to process"
                                                           *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1) && (processedStudents.indexOf(data.id) !== -1) && data['processing_status'] === 'PROCESSING' "></i>
                                                        <i aria-hidden="true"
                                                           class="fab fa-artstation f_20 cursor-pointer font-weight-light mt-1"
                                                           *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1"
                                                           [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1))}"
                                                           (click)="navigateToUrl('admission-form/' + data.id)"></i>
                                                        <i aria-hidden="true"
                                                           class="fas fa-print f_20 text-primary cursor-pointer mt-1 ml-1"
                                                           title="Print Form" (click)="goToPrintForm(data)"
                                                           *ngIf="(['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === ''))"></i>
                                                        <i class="fas fa-bug f_20 text-danger cursor-pointer mt-1 ml-1"
                                                           title="Error remark"
                                                           *ngIf="(['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['processing_remark'])"
                                                           (click)="showErrorRemark(content, data)"></i>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1">
                                                            {{data?.head_counsellor?.name}}
                                                        </h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1">
                                                            {{data?.business_center?.business_center_detail?.code}}
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.username}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.pro_id}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.pro_id}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.enrollment_id}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.enrollment_id}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="data && data['status_id'] && data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.roll_no}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data && data['status_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">{{data?.roll_no}}</h6>
                                                        <h6 class="mt-1 cursor-pointer"
                                                            *ngIf="(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="data['status_id'] === 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'IN PROGRESS') ? 'In Progress' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                                        </h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1 && data['stage_id'] !== 10 &&
                                                            !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')">
                                                            {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'IN PROGRESS') ? 'In Progress' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                                        </h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary text-capitalize"
                                                            *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1 && data['status_id'] !== 10 && !(!data['father_name'] || data['father_name'] === '' || !data['mother_name'] || data['mother_name'] === '' || !data['date_of_birth'] || data['date_of_birth'] === '')"
                                                            (click)="changeStatusModal(content, data)">
                                                            {{(data && data['status'] && data['status'] === 'pending') ? 'Pending' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'form_received') ? 'Form Received' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'sent') ? 'Sent' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'IN PROGRESS') ? 'In Progress' : ''}}
                                                            {{(data && data['status'] && data['status'] === 'DOCUMENTS UPLOADED') ? 'Documents Uploaded' : ''}}
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <img [src]="data['photograph'].url" class="width-40px"
                                                             *ngIf="data && data['photograph'] && data['photograph'].url"/>
                                                        <h6 *ngIf="!data || !data['photograph'] || !data['photograph'].url">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.father_name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.mother_name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && !data.course && data['register_course']">{{data?.register_course}}</h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data.course && data.branch">{{data?.course}}
                                                            ({{data?.branch}})</h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data.course && !data.branch">{{data?.course}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.email}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.phone}}</h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_actual">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_actual) ? data['business_center']['business_center_detail'].admission_percentage_actual : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].admission_percentage_actual">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_actual">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_actual) ? data['business_center']['business_center_detail'].re_registration_percentage_display : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].re_registration_percentage_actual">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_display">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].admission_percentage_display) ? data['business_center']['business_center_detail'].admission_percentage_display : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].admission_percentage_display">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center"
                                                        *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_display">
                                                            {{(data && data['business_center'] && data['business_center']['business_center_detail'] && data['business_center']['business_center_detail'].re_registration_percentage_display) ? data['business_center']['business_center_detail'].re_registration_percentage_display : ''}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['business_center'] || !data['business_center']['business_center_detail'] || !data['business_center']['business_center_detail'].re_registration_percentage_display">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['prospectus_fee'] && data['prospectus_fee'] != '-'">
                                                            {{data['prospectus_fee'] | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['prospectus_fee'] || data['prospectus_fee'] === '-'">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['pcb_mapping'] && data['pcb_mapping']['registration_amount']">
                                                            {{data?.pcb_mapping?.registration_amount | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['pcb_mapping'] || !data['pcb_mapping']['registration_amount']">
                                                            -</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1" *ngIf="data && data['chargeableCourseFee']">
                                                            {{data?.chargeableCourseFee | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1" *ngIf="!data || !data['chargeableCourseFee']">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['service_charge'] && data['service_charge'] != '-'">
                                                            {{data['service_charge'] | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['service_charge'] || data['service_charge'] === '-'">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['service_charge'] && data['examination_fee'] != '-'">
                                                            {{data['examination_fee'] | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['examination_fee'] || data['examination_fee'] === '-'">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data['pcb_mapping'] && data['pcb_mapping']['re_registration_amount']">
                                                            {{data?.pcb_mapping?.re_registration_amount | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['pcb_mapping'] || !data['pcb_mapping']['re_registration_amount']">
                                                            -
                                                        </h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1" *ngIf="data && data['total']">
                                                            {{data?.total | IndianNumber}}
                                                        </h6>
                                                        <h6 class="mt-1" *ngIf="!data || !data['total']">-
                                                        </h6>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <ng-template #noDataToShowWeb>
                                                    <tbody>
                                                    <tr>
                                                        <td colspan="13" class="text-center"
                                                            *ngIf="['BUSINESS_ADMIN', 'BUSINESS_HEAD_CENTER', 'BUSINESS_CENTER'].indexOf(user['user_type']) !== -1">
                                                            <h6>No data to show</h6>
                                                        </td>
                                                        <td colspan="9" class="text-center"
                                                            *ngIf="['SUPER_ADMIN', 'UNIVERSITY'].indexOf(user['user_type']) !== -1">
                                                            <h6>No data to show</h6>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </ng-template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row pagination-table mt-2">
                                        <ngb-pagination class="pagination-primary"
                                                        [collectionSize]="studentPagination.total"
                                                        [(page)]="studentPagination.page" [boundaryLinks]="true"
                                                        [pageSize]="studentPagination.perpage" [maxSize]="5"
                                                        (pageChange)="onStudentPageChange($event)">
                                            <ng-template ngbPaginationPrevious
                                            ><span [data-feather]="'chevron-left'" [size]="18"
                                                   [class]="'font-weight-bolder'"></span>
                                            </ng-template>
                                            <ng-template ngbPaginationNext
                                            ><span [data-feather]="'chevron-right'" [size]="18"
                                                   [class]="'font-weight-bolder'"></span
                                            ></ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="navFilled" class="mt-2"></div>
                </div>
            </div>
        </section>
    </div>
</div>
