import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityService} from '../../shared/services/university.service';
import {StorageService} from '../../shared/services/storage.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {StudentService} from '../../shared/services/student.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';

@Component({
    selector: 'app-business-head-center-share',
    templateUrl: './business-head-center-share.component.html',
    styleUrls: ['./business-head-center-share.component.scss']
})
export class BusinessHeadCenterShareComponent implements OnInit {
    showSearch = false;
    studentSessionForm: FormGroup;
    businessHeadCentersShare = [];
    universities = [];
    studentSessionYears: any = [];
    data: any;
    businessAdminDetail: any;
    updateBusinessHeadCenterShareForm: FormGroup;
    assignUniversityForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    user: any;
    businessHeadCenterShareData: any;
    settings: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                public studentService: StudentService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private universitySettingService: UniversitySettingService,
                private businessAdminService: BusinessAdminService,
                private universityService: UniversityService) {
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        this.refresh();
    }

    refresh() {
        this.updateBusinessHeadCenterShareForm = this.fb.group({
            'admission_percentage_actual': [''],
            're_registration_percentage_actual': [''],
            'admission_percentage_display': [''],
            're_registration_percentage_display': [''],
            'prospectus_fee': [''],
            'service_charge': [''],
            'examination_fee': ['']
        });
        this.assignUniversityForm = this.fb.group({
            'university_id': ['']
        });
        this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'code': ['']
        });
        this.getBusinessAdminDetail();
    }

    getBusinessAdminDetail() {
        this.businessAdminService.detailById(this.user['userId']).subscribe(data => {
            this.businessAdminDetail = data;
            this.getSettings();
        });
    }

    getSettings() {
        this.universitySettingService.getByAdmin(this.businessAdminDetail['university_id'], {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    changeSettings(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            let selectedSetting = {};
            this.studentSessionYears.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSetting = session;
                }
            });
            this.get();
        }
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            this.get();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params.setting_id = (this.studentSessionForm && this.studentSessionForm.value) ? this.studentSessionForm.value.student_session_year : '';
        this.submitting = true;
        this.businessHeadCentersShare = [];
        this.businessHeadCenterService.getSharePercentage(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.businessHeadCentersShare = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.businessHeadCentersShare = [];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page
                };
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'code': (this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'code': (this.searchForm && this.searchForm.value && this.searchForm.value.code) ? this.searchForm.value.code : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
    }

    updateStatus(status, data) {
        /*this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.businessAdminService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.passwordId = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.passwordId = undefined;
            });
        }*/
    }

    updatePercentageModal(content, data) {
        this.businessHeadCenterShareData = data;
        this.updateBusinessHeadCenterShareForm.patchValue({
            'admission_percentage_actual': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.admission_percentage_actual) ? this.businessHeadCenterShareData.admission_percentage_actual : '',
            're_registration_percentage_actual': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.re_registration_percentage_actual) ? this.businessHeadCenterShareData.re_registration_percentage_actual : '',
            'admission_percentage_display': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.admission_percentage_display) ? this.businessHeadCenterShareData.admission_percentage_display : '',
            're_registration_percentage_display': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.re_registration_percentage_display) ? this.businessHeadCenterShareData.re_registration_percentage_display : '',
            'prospectus_fee': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.prospectus_fee) ? this.businessHeadCenterShareData.prospectus_fee : '',
            'service_charge': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.service_charge) ? this.businessHeadCenterShareData.service_charge : '',
            'examination_fee': (this.businessHeadCenterShareData && this.businessHeadCenterShareData.examination_fee) ? this.businessHeadCenterShareData.examination_fee : '',
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    create() {
        this.submitted = true;
        if (!this.updateBusinessHeadCenterShareForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'head_center_id': (this.businessHeadCenterShareData && this.businessHeadCenterShareData['business_head_center']) ? this.businessHeadCenterShareData['business_head_center']['user_id'] : '',
                'admission_percentage_actual': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.admission_percentage_actual) ? this.updateBusinessHeadCenterShareForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_actual) ? this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.admission_percentage_display) ? this.updateBusinessHeadCenterShareForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_display) ? this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.prospectus_fee) ? this.updateBusinessHeadCenterShareForm.value.prospectus_fee : '',
                'service_charge': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.service_charge) ? this.updateBusinessHeadCenterShareForm.value.service_charge : '',
                'examination_fee': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.examination_fee) ? this.updateBusinessHeadCenterShareForm.value.examination_fee : '',
                'setting_id': (this.businessHeadCentersShare && this.businessHeadCentersShare['university_setting'] && this.businessHeadCentersShare['university_setting'].id) ? this.businessHeadCentersShare['university_setting'].id : '',
            };
            this.submitting = true;
            this.businessHeadCenterService.createSharePercentage(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateBusinessHeadCenterShareForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': '',
                    'service_charge': '',
                    'examination_fee': '',
                });
                this.modalService.dismissAll('');
                // this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.updateBusinessHeadCenterShareForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'head_center_id': (this.businessHeadCenterShareData && this.businessHeadCenterShareData['business_head_center']) ? this.businessHeadCenterShareData['business_head_center']['user_id'] : '',
                'admission_percentage_actual': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.admission_percentage_actual) ? this.updateBusinessHeadCenterShareForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_actual) ? this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.admission_percentage_display) ? this.updateBusinessHeadCenterShareForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_display) ? this.updateBusinessHeadCenterShareForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.prospectus_fee) ? this.updateBusinessHeadCenterShareForm.value.prospectus_fee : '',
                'service_charge': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.service_charge) ? this.updateBusinessHeadCenterShareForm.value.service_charge : '',
                'examination_fee': (this.updateBusinessHeadCenterShareForm.value && this.updateBusinessHeadCenterShareForm.value.examination_fee) ? this.updateBusinessHeadCenterShareForm.value.examination_fee : ''
            };
            this.submitting = true;
            this.businessHeadCenterService.updateSharePercentage(this.businessHeadCenterShareData['university_setting']['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateBusinessHeadCenterShareForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': '',
                    'service_charge': '',
                    'examination_fee': ''
                });
                this.modalService.dismissAll('');
                this.get({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    downloadExcel() {
        this.businessHeadCenterService.downloadExcel({}).subscribe(data => {
            if (data && data['url']) {
                window.open(data['url'], '_blank');
            }
        });
    }
}
