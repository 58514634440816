import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {UniversityService} from '../../../shared/services/university.service';
import {StateService} from '../../../shared/services/state.service';
import {BusinessHeadCenterService} from '../../../shared/services/business-head-center.service';

@Component({
    selector: 'app-add-business-headcenters',
    templateUrl: './add-business-head-centers.component.html',
    styleUrls: ['./add-business-head-centers.component.scss']
})
export class AddBusinessHeadCentersComponent implements OnInit, OnChanges {
    addBusinessHeadCenterForm: FormGroup;
    id: any;
    universities: any = [];
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    states: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private businessHeadcenterService: BusinessHeadCenterService,
                private universityService: UniversityService,
                private stateService: StateService) {
    }

    ngOnInit(): void {
        this.getStates();
    }

    ngOnChanges(): void {
        this.refresh();

    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-business-head-center').toggleOpen();
    }

    refresh() {
        this.addBusinessHeadCenterForm = this.fb.group({
            'name': ['', Validators.required],
            'username': ['', (!this.id) ? Validators.required : ''],
            'code': ['', (!this.id) ? Validators.required : ''],
            'email': ['', Validators.compose([Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'password': ['', (!this.data || !this.data['id']) ? Validators.required : ''],
            'address': [''],
            'state_id': [''],
            'pin': [''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
        //this.getUniversityId();
    }

    patchForm() {
        this.addBusinessHeadCenterForm.patchValue({
            'name': (this.data && this.data && this.data && this.data['name']) ? this.data['name'] : '',
            'username': (this.data && this.data && this.data['username']) ? this.data['username'] : '',
            'email': (this.data && this.data && this.data['email']) ? this.data['email'] : '',
            'contact': (this.data && this.data && this.data['contact']) ? this.data['contact'] : '',
            'code': (this.data && this.data['business_head_center_detail'] && this.data['business_head_center_detail']['code']) ? this.data['business_head_center_detail']['code'] : '',
            'address': (this.data && this.data['business_head_center_detail'] && this.data['business_head_center_detail']['address']) ? this.data['business_head_center_detail']['address'] : '',
            'state_id': (this.data && this.data['business_head_center_detail'] && this.data['business_head_center_detail']['state_id']) ? this.data['business_head_center_detail']['state_id'] : '',
            'pin': (this.data && this.data['business_head_center_detail'] && this.data['business_head_center_detail']['pin']) ? this.data['business_head_center_detail']['pin'] : '',
        });
    }

    getUniversityId() {
        this.universityService.get(this.data).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.universities = data['data'];
            }
        });
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addBusinessHeadCenterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.name) ? this.addBusinessHeadCenterForm.value.name : '',
                'username': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.username) ? this.addBusinessHeadCenterForm.value.username : '',
                'code': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.code) ? this.addBusinessHeadCenterForm.value.code : '',
                'email': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.email) ? this.addBusinessHeadCenterForm.value.email : '',
                'contact': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.contact) ? this.addBusinessHeadCenterForm.value.contact : '',
                'password': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.password) ? this.addBusinessHeadCenterForm.value.password : '',
                'address': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.address) ? this.addBusinessHeadCenterForm.value.address : '',
                'state_id': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.state_id) ? this.addBusinessHeadCenterForm.value.state_id : '',
                'pin': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.pin) ? this.addBusinessHeadCenterForm.value.pin : '',
            };
            this.submitting = true;
            this.businessHeadcenterService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addBusinessHeadCenterForm.reset();
                    this.addBusinessHeadCenterForm.patchValue({
                        'name': '',
                        'username': '',
                        'code': '',
                        'email': '',
                        'contact': '',
                        'password': '',
                        'address': '',
                        'state_id': '',
                        'pin': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].code && error['error']['errors'].code.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].code[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addBusinessHeadCenterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.name) ? this.addBusinessHeadCenterForm.value.name : '',
                'username': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.username) ? this.addBusinessHeadCenterForm.value.username : '',
                'code': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.code) ? this.addBusinessHeadCenterForm.value.code : '',
                'email': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.email) ? this.addBusinessHeadCenterForm.value.email : '',
                'contact': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.contact) ? this.addBusinessHeadCenterForm.value.contact : '',
                'address': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.address) ? this.addBusinessHeadCenterForm.value.address : '',
                'state_id': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.state_id) ? this.addBusinessHeadCenterForm.value.state_id : '',
                'pin': (this.addBusinessHeadCenterForm.value && this.addBusinessHeadCenterForm.value.pin) ? this.addBusinessHeadCenterForm.value.pin : '',
            };
            this.submitting = true;
            this.businessHeadcenterService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].code && error['error']['errors'].code.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].code[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }
}
