import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessAdminSettingsRoutingModule} from './business-admin-settings-routing.module';
import {BusinessAdminSettingsComponent} from './business-admin-settings.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [BusinessAdminSettingsComponent],
    imports: [
        CommonModule,
        CommonModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        BusinessAdminSettingsRoutingModule,
        UiSwitchModule
    ],
    exports: [
        BusinessAdminSettingsComponent
    ]
})

export class BusinessAdminSettingsModule {
}
