import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessAdminsComponent} from './business-admins.component';

const routes: Routes = [
    {
        path: 'business-admins',
        component: BusinessAdminsComponent,
        data: {animation: 'business-admins'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessAdminsRoutingModule {
}
