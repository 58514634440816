import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {UniversityService} from '../../../shared/services/university.service';
import {StateService} from '../../../shared/services/state.service';
import {BusinessCenterService} from '../../../shared/services/business-center.service';

@Component({
    selector: 'app-add-business-centers',
    templateUrl: './add-business-centers.component.html',
    styleUrls: ['./add-business-centers.component.scss']
})
export class AddBusinessCentersComponent implements OnInit, OnChanges {
    addBusinessCenterForm: FormGroup;
    universities: any = [];
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    states: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private businessCenterService: BusinessCenterService,
                private universityService: UniversityService,
                private stateService: StateService) {
    }

    ngOnInit(): void {
        this.getStates();
    }

    ngOnChanges(): void {
        this.refresh();

    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-business-center').toggleOpen();
    }

    refresh() {
        this.addBusinessCenterForm = this.fb.group({
            'name': ['', Validators.required],
            'username': ['', (!this.data['data'] || !this.data['data']['id']) ? Validators.required : ''],
            'code': ['', (!this.data['data'] || !this.data['data']['id']) ? Validators.required : ''],
            'email': ['', Validators.compose([Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'password': ['', (!this.data['data'] || !this.data['data']['id']) ? Validators.required : ''],
            'address': [''],
            'state_id': [''],
            'pin': [''],
        });
        if (this.data && this.data['data']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addBusinessCenterForm.patchValue({
            'name': (this.data && this.data['data'] && this.data['data']['name']) ? this.data['data']['name'] : '',
            'username': (this.data && this.data['data'] && this.data['data']['username']) ? this.data['data']['username'] : '',
            'code': (this.data && this.data['data'] && this.data['data']['business_center_detail'] && this.data['data']['business_center_detail']['code']) ? this.data['data']['business_center_detail']['code'] : '',
            'email': (this.data && this.data['data'] && this.data['data']['email']) ? this.data['data']['email'] : '',
            'contact': (this.data && this.data['data'] && this.data['data']['contact']) ? this.data['data']['contact'] : '',
            'address': (this.data && this.data['data'] && this.data['data']['business_center_detail'] && this.data['data']['business_center_detail']['address']) ? this.data['data']['business_center_detail']['address'] : '',
            'state_id': (this.data && this.data['data'] && this.data['data']['business_center_detail'] && this.data['data']['business_center_detail']['state'] && this.data['data']['business_center_detail']['state'].id) ? this.data['data']['business_center_detail']['state'].id : '',
            'pin': (this.data && this.data['data'] && this.data['data']['business_center_detail'] && this.data['data']['business_center_detail']['pin']) ? this.data['data']['business_center_detail']['pin'] : '',
        });
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addBusinessCenterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.name) ? this.addBusinessCenterForm.value.name : '',
                'username': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.username) ? this.addBusinessCenterForm.value.username : '',
                'head_center_id': (this.data && this.data['head_center_id']) ? this.data['head_center_id'] : '',
                'email': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.email) ? this.addBusinessCenterForm.value.email : '',
                'code': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.code) ? this.addBusinessCenterForm.value.code : '',
                'contact': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.contact) ? this.addBusinessCenterForm.value.contact : '',
                'password': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.password) ? this.addBusinessCenterForm.value.password : '',
                'address': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.address) ? this.addBusinessCenterForm.value.address : '',
                'state_id': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.state_id) ? this.addBusinessCenterForm.value.state_id : '',
                'pin': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.pin) ? this.addBusinessCenterForm.value.pin : '',
            };
            this.submitting = true;
            this.businessCenterService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addBusinessCenterForm.reset();
                    this.addBusinessCenterForm.patchValue({
                        'name': '',
                        'username': '',
                        'code': '',
                        'email': '',
                        'contact': '',
                        'password': '',
                        'address': '',
                        'state_id': '',
                        'pin': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].code && error['error']['errors'].code.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].code[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addBusinessCenterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.name) ? this.addBusinessCenterForm.value.name : '',
                'username': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.username) ? this.addBusinessCenterForm.value.username : '',
                'code': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.code) ? this.addBusinessCenterForm.value.code : '',
                'email': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.email) ? this.addBusinessCenterForm.value.email : '',
                'contact': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.contact) ? this.addBusinessCenterForm.value.contact : '',
                'address': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.address) ? this.addBusinessCenterForm.value.address : '',
                'state_id': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.state_id) ? this.addBusinessCenterForm.value.state_id : '',
                'pin': (this.addBusinessCenterForm.value && this.addBusinessCenterForm.value.pin) ? this.addBusinessCenterForm.value.pin : '',
            };
            this.submitting = true;
            this.businessCenterService.update(this.data['data'].id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].code && error['error']['errors'].code.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].code[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }
}
