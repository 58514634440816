<div class="content-wrapper">
    <div class="content-body">
        <section id="business-admin-settings">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="mt-5px">Settings</h2>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th>
                                                <h6 class="mt-1 mb-1 font-weight-bold">Role</h6>
                                            </th>
                                            <th class="text-right">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Business Head Centers
                                            </td>
                                            <td class="text-center">
                                                <ui-switch id="business_head_center" size="small"
                                                           class="pull-right"
                                                           checkedLabel="Allow"
                                                           (change)="businessHeadCenterStatus($event)"
                                                           uncheckedLabel="Disallow"
                                                           [checked]="(businessSetting && businessSetting['business_head_center_status'] && businessSetting['business_head_center_status'] === 'ACTIVE') ? true : false"></ui-switch>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Business Centers
                                            </td>
                                            <td>
                                                <ui-switch id="business_center" class="pull-right" size="small"
                                                           checkedLabel="Allow"
                                                           (change)="businessCenterStatus($event)"
                                                           uncheckedLabel="Disallow"
                                                           [checked]="(businessSetting && businessSetting['business_center_status'] && businessSetting['business_center_status'] === 'ACTIVE') ? true : false"></ui-switch>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
