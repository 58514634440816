<div class="content-wrapper">
    <ng-template #content>
        <div id="modal">
            <div class="modal-header font-weight-bold">
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="modalType === 'RECEIPT_BULK_UPLOAD'">
                    Bulk Upload - Accounting Receipts Data
                </h4>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="modalService.dismissAll('')"
                >
                    ×
                </button>
            </div>
            <div class="modal-body">
                <div class="row p-2" *ngIf="modalType === 'RECEIPT_BULK_UPLOAD'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body text-center" *ngIf="showFetchingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, updating students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center" *ngIf="showUpdatingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, fetching students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center"
                             *ngIf="!bulkReceiptData || bulkReceiptData.length === 0 && !showFetchingDataLoader">
                            <i class="fas fa-file-excel fa-5x p-100px text-success cursor-pointer"
                               (click)="fileUploadBulk.click()"></i>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onFileChange($event, 'STUDENT_BULK_RECEIPT_UPLOAD')" #fileUploadBulk>
                            <h6>Upload New Excel (supported format xls, xlsx)</h6>
                        </div>
                        <div class="card-body mt-1 text-center overflow-x-unset overflow-y-scroll max-height-700px"
                             *ngIf="bulkReceiptData && bulkReceiptData.length > 0 && !showFetchingDataLoader">
                            <table class="table table-list shadow-complete">
                                <thead>
                                <tr>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Type</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Code</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Amount</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Date</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Transaction Type</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Transaction Id</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Bank</h6>
                                    </th>
                                </tr>
                                </thead>
                                <tbody *ngIf="bulkReceiptData && bulkReceiptData.length > 0">
                                <tr *ngFor="let bulkReceipt of bulkReceiptData; let i = index">
                                    <td>{{i + 1}}.</td>
                                    <td class="text-center">
                                        <i class="fas fa-trash text-danger cursor-pointer"
                                           (click)="removeProId(i)"></i>
                                    </td>
                                    <td>
                                        <select class="form-control mt-1"
                                                [ngClass]="{'mt-30px': bulkReceipt && bulkReceipt['errors'] && (bulkReceipt['errors'].type_message)}"
                                                [(ngModel)]="bulkReceipt.type">
                                            <option value="">Select Type</option>
                                            <option value="head center">Head Center</option>
                                            <option value="center">Center</option>
                                        </select>
                                        <small class="text-danger">
                                            <span *ngIf="bulkReceipt && bulkReceipt['errors'] && bulkReceipt['errors'].type_message">{{bulkReceipt['errors'].type_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkReceipt && bulkReceipt['errors'] && (bulkReceipt['errors'].code_message)}"
                                               [(ngModel)]="bulkReceipt.code"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkReceipt && bulkReceipt['errors'] && bulkReceipt['errors'].code_message">{{bulkReceipt['errors'].code_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="number"
                                               [ngClass]="{'mt-30px': bulkReceipt && bulkReceipt['errors'] && (bulkReceipt['errors'].amount_message)}"
                                               [(ngModel)]="bulkReceipt.amount"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkReceipt && bulkReceipt['errors'] && bulkReceipt['errors'].amount_message">{{bulkReceipt['errors'].amount_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="date"
                                               [ngClass]="{'mt-30px': bulkReceipt && bulkReceipt['errors'] && (bulkReceipt['errors'].date_message)}"
                                               [(ngModel)]="bulkReceipt.date"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkReceipt && bulkReceipt['errors'] && bulkReceipt['errors'].date_message">{{bulkReceipt['errors'].date_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <select class="form-control mt-1" type="text" [(ngModel)]="bulkReceipt.transaction_type">
                                            <option value="NEFT">NEFT</option>
                                            <option value="RTGS">RTGS</option>
                                            <option value="DD">DD</option>
                                            <option value="IMPS">IMPS</option>
                                            <option value="UPI">UPI</option>
                                            <option value="CHEQUE">CHEQUE</option>
                                            <option value="CASH">CASH</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text" [(ngModel)]="bulkReceipt.transaction_id"/>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text" [(ngModel)]="bulkReceipt.bank"/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitBulkReceipt()"
                        *ngIf="modalType === 'RECEIPT_BULK_UPLOAD'"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
            </div>
        </div>
    </ng-template>
    <div class="content-body">
        <section id="accounting">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h2 class="mt-5px">Accounting</h2>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center" *ngIf="!decodedOtp">
                    <h4 class="mt-10px" *ngIf="user && user['user_type'] !== 'ACCOUNTING'">Processable Credits :
                        &#8377; {{creditsProcessable | IndianNumber}}</h4>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="!decodedOtp">
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Bulk Upload Receipts" *ngIf="['ACCOUNTING'].indexOf(user['user_type']) !== -1"
                            (click)="openReceiptBulkUploadModal(content)">
                        <i class="fas fa-cloud-upload-alt color-white f_20 cursor-pointer"></i>
                    </button>
                    <button class="btn btn-icon btn-primary pull-right ml-6px waves-effect waves-float waves-light"
                            (click)="goToReceipt()" title="Receipts">
                        <i aria-hidden="true" class="fas fa-receipt f_20"></i>
                    </button>
                    <button class="btn btn-icon btn-primary pull-right ml-6px waves-effect waves-float waves-light"
                            (click)="goToProcessStudent()"
                            *ngIf="user && (user['user_type'] === 'BUSINESS_HEAD_CENTER' || user['user_type'] === 'BUSINESS_CENTER')"
                            title="Process Students">
                        <i aria-hidden="true" class="fas fa-user-graduate f_20"></i>
                    </button>
                    <button class="btn btn-icon btn-primary pull-right ml-6px waves-effect waves-float waves-light"
                            (click)="goToTransactions()" *ngIf="user && user['user_type'] === 'ACCOUNTING'"
                            title="Transactions">
                        <i aria-hidden="true" class="fas fa-coins f_20"></i>
                    </button>
                </div>
            </div>
            <div class="row mt-17px">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeSettings($event)">
                                <option class="text-center" [value]="setting.id"
                                        *ngFor=" let setting of settings">{{setting?.session_name}}
                                    - {{setting?.session_year}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="row" *ngIf="user && user['user_type'] === 'ACCOUNTING'">
                        <div class="col-xl-12 col-md-12 col-12">
                            <div class="card card-statistics">
                                <div class="card-header">
                                    <h4 class="card-title">Transactions</h4>
                                </div>
                                <div class="card-body statistics-body">
                                    <div class="row">
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        &#8377; {{transaction | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Transactions - Total</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        &#8377; {{receiptTotal | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Receipts - Total</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        &#8377; {{receiptAccepted | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Receipts - Accepted</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        &#8377; {{receiptPending | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Receipts - Pending</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                         *ngIf="user && (user['user_type'] === 'BUSINESS_CENTER' || user['user_type'] === 'BUSINESS_HEAD_CENTER') && !decodedOtp">
                        <div class="col-xl-12 col-md-12 col-12">
                            <div class="card card-statistics">
                                <div class="card-header">
                                    <h4 class="card-title">Students</h4>
                                </div>
                                <div class="card-body statistics-body">
                                    <div class="row">
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        {{totalStudent | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Students - Total</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        {{studentsProcessable | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Students - Processable</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        {{studentsProcessing | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Students - Processing</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                            <div class="media">
                                                <div class="avatar bg-light-success mr-2">
                                                    <div class="avatar-content">
                                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                                    </div>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h4 class="font-weight-bolder mb-0">
                                                        {{studentsProcessed | IndianNumber}}</h4>
                                                    <p class="card-text font-small-3 mb-0">Students - Processed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                         *ngIf="user && (user['user_type'] === 'BUSINESS_CENTER' || user['user_type'] === 'BUSINESS_HEAD_CENTER') && decodedOtp">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12"></div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                            <div class="card pb-2 pl-2 pr-2">
                                <div class="card-header">
                                    <h4>Accounting One Time Password</h4>
                                </div>
                                <div class="card-body">
                                    <form class="form mb--8px" novalidate [formGroup]="accountingOtpForm">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <fieldset class="form-group">
                                                    <input class="form-control" type="number" formControlName="otp"
                                                           placeholder="Enter OTP">
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                                                <small>Resend OTP in - </small>
                                                <small class="text-danger mr-1"
                                                       *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
                                                <small class="text-primary cursor-pointer"
                                                       *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP
                                                    ?</small>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
                                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                                        (click)="submitOtp()"
                                                        [disabled]="submitting">
                                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
