<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <ng-template #content let-modal>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Update Session Percentage</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body" ngbAutoFocus>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updateBusinessHeadCenterShareForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="admission_percentage_actual">Admission Actual
                                                        (%)</label>
                                                    <input class="form-control" id="admission_percentage_actual"
                                                           formControlName="admission_percentage_actual" type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('admission_percentage_actual').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="re_registration_percentage_actual">Re - Registration
                                                        Actual (%)</label>
                                                    <input class="form-control" id="re_registration_percentage_actual"
                                                           formControlName="re_registration_percentage_actual"
                                                           type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('re_registration_percentage_actual').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="admission_percentage_display">Admission Display
                                                        (%)</label>
                                                    <input class="form-control" id="admission_percentage_display"
                                                           formControlName="admission_percentage_display" type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('admission_percentage_display').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="re_registration_percentage_display">Re - Registration
                                                        Display (%)</label>
                                                    <input class="form-control" id="re_registration_percentage_display"
                                                           formControlName="re_registration_percentage_display"
                                                           type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('re_registration_percentage_display').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="prospectus_fee">Prospectus Fee</label>
                                                    <input class="form-control" id="prospectus_fee"
                                                           formControlName="prospectus_fee"
                                                           type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('prospectus_fee').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="service_charge">Service Charge</label>
                                                    <input class="form-control" id="service_charge"
                                                           formControlName="service_charge"
                                                           type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('service_charge').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="examination_fee">Examination Fee</label>
                                                    <input class="form-control" id="examination_fee"
                                                           formControlName="examination_fee"
                                                           type="number">
                                                    <small *ngIf="submitted && updateBusinessHeadCenterShareForm.get('examination_fee').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="update()"
                                *ngIf="businessHeadCenterShareData && businessHeadCenterShareData['admission_percentage_actual']">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="create()"
                                *ngIf="businessHeadCenterShareData && !businessHeadCenterShareData['admission_percentage_actual']">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Business Head Centers Share</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Excel" (click)="downloadExcel()"
                            *ngIf="user && ['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Search" (click)="showSearch = !showSearch">
                        <i class="fa fa-search color-white f_20 cursor-pointer p-3px"></i>
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name" placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="code"
                                           placeholder="Code">
                                </fieldset>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="content-body">
                <div class="row mt-17px">
                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <form class="row" novalidate [formGroup]="studentSessionForm">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <select class="form-control" formControlName="student_session_year"
                                        (change)="changeSettings($event)">
                                    <option class="text-center" [value]="setting.id"
                                            *ngFor=" let setting of settings">{{setting?.session_name}} - {{setting?.session_year}}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Code</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Admission Actual (%)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Admission Display (%)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Re-Registration Actual (%)</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Re-Registration Display (%)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Prospectus Fee</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Service Charge</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Examination Fee</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="businessHeadCentersShare && businessHeadCentersShare.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let businessHeadCenterShare of businessHeadCentersShare; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessHeadCenterShare?.business_head_center?.self?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessHeadCenterShare?.business_head_center?.code}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessHeadCenterShare?.admission_percentage_actual}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessHeadCenterShare?.admission_percentage_display}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessHeadCenterShare?.re_registration_percentage_actual}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessHeadCenterShare?.re_registration_percentage_display}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="businessHeadCenterShare && businessHeadCenterShare['prospectus_fee']">
                                                    {{businessHeadCenterShare?.prospectus_fee}}
                                                </h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!businessHeadCenterShare || !businessHeadCenterShare['prospectus_fee']">
                                                    -
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="businessHeadCenterShare && businessHeadCenterShare['service_charge']">
                                                    {{businessHeadCenterShare?.service_charge}}
                                                </h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!businessHeadCenterShare || !businessHeadCenterShare['service_charge']">
                                                    -
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="businessHeadCenterShare && businessHeadCenterShare['examination_fee']">
                                                    {{businessHeadCenterShare?.examination_fee}}
                                                </h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!businessHeadCenterShare || !businessHeadCenterShare['examination_fee']">
                                                    -
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-info cursor-pointer"
                                                    (click)="updatePercentageModal(content, businessHeadCenterShare)">
                                                    Update</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="11" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="businessHeadCentersShare && businessHeadCentersShare.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let businessHeadCenterShare of businessHeadCentersShare; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{businessHeadCenterShare?.name}}</h4>
                                </div>
                                <!--<div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Executive" *ngIf="admin?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', admin)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Executive" *ngIf="admin?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', admin)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(admin)"></i>
                                </div>-->
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Username - {{admin?.username}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Email - {{admin?.email}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{admin?.contact}}
                                </div>
                                <!--<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Password - <span class="text-info" (click)="updatePasswordModal(content, admin.id)">Update</span>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious>
                        <span [data-feather]="'chevron-left'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <span [data-feather]="'chevron-right'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
