import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UniversitySettingsComponent} from './university-settings.component';
import {AddUniversitySettingComponent} from './add-university-setting/add-university-setting.component';
import {BackSessionEntryUniversitySettingComponent} from './back-session-entry-university-setting/back-session-entry-university-setting.component';

const routes: Routes = [
    {
        path: 'university-settings',
        component: UniversitySettingsComponent,
        data: {animation: 'university-settings'}
    },
    {
        path: 'university-setting/add',
        component: AddUniversitySettingComponent,
        data: {animation: 'university-setting/add'}
    },
    {
        path: 'university-setting/:id/back-session-entry',
        component: BackSessionEntryUniversitySettingComponent,
        data: {animation: 'university-setting/:id/back-session-entry'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UniversitySettingsRoutingModule {
}
