import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {NotificationService} from '../../../shared/services/notification.service';

@Component({
    selector: 'app-add-notification',
    templateUrl: './add-notification.component.html',
    styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnChanges {
    addNotificationForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private notificationService: NotificationService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-notification').toggleOpen();
    }

    refresh() {
        this.addNotificationForm = this.fb.group({
            'notification': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addNotificationForm.patchValue({
            'notification': (this.data && this.data['notification']) ? this.data['notification'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addNotificationForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'notification': (this.addNotificationForm.value && this.addNotificationForm.value.notification) ? this.addNotificationForm.value.notification : ''
            };
            this.submitting = true;
            this.notificationService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addNotificationForm.reset();
                    this.addNotificationForm.patchValue({
                        'notification': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].notification && error['error']['errors'].notification.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].notification[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addNotificationForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'notification': (this.addNotificationForm.value && this.addNotificationForm.value.notification) ? this.addNotificationForm.value.notification : ''
            };
            this.submitting = true;
            this.notificationService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].notification && error['error']['errors'].notification.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].notification[0]);
                        return;
                    }
                }
            });
        }
    }
}
