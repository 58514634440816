<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <h2 class="mt-5px">Credit Transactions</h2>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="transaction_id"
                                           placeholder="Transaction Id">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name"
                                           placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="code"
                                           placeholder="Code">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="date" formControlName="date" placeholder="Date">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="amount"
                                           placeholder="Amount">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row mt-17px">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <form class="row" novalidate [formGroup]="studentSessionForm">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <select class="form-control" formControlName="student_session_year"
                                    (change)="changeSettings($event)">
                                <option class="text-center" [value]="setting.id"
                                        *ngFor=" let setting of settings">{{setting?.session_name}}
                                    - {{setting?.session_year}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Transaction
                                                Id</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">User
                                                Type</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Code</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Date</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Amount</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="creditTransactions && creditTransactions.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let creditTransaction of creditTransactions; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="creditTransaction && creditTransaction['transaction_id']">{{creditTransaction?.transaction_id}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!creditTransaction || !creditTransaction['transaction_id']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="creditTransaction && creditTransaction['user_type'] && creditTransaction['user_type'] === 'BUSINESS_HEAD_CENTER'">
                                                    Business Head Center</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="creditTransaction && creditTransaction['user_type'] && creditTransaction['user_type'] === 'BUSINESS_CENTER'">
                                                    Business Center</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!creditTransaction || !creditTransaction['user_type']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="creditTransaction && creditTransaction['center_name']">{{creditTransaction?.center_name}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!creditTransaction || !creditTransaction['center_name']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="creditTransaction && creditTransaction['center_code']">{{creditTransaction?.center_code}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!creditTransaction || !creditTransaction['center_code']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="creditTransaction && creditTransaction['date']">{{creditTransaction?.date}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!creditTransaction || !creditTransaction['date']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-success"
                                                    *ngIf="creditTransaction && creditTransaction['amount'] && creditTransaction['amount'] > 0">
                                                    &#8377; {{creditTransaction['amount'] | IndianNumber}}&#8593;
                                                </h6>
                                                <h6 class="mt-1 text-danger"
                                                    *ngIf="creditTransaction && creditTransaction['amount'] && creditTransaction['amount'] < 0">
                                                    &#8377; {{creditTransaction['amount'].substring(1) | IndianNumber}}&#8595;
                                                </h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!creditTransaction || !creditTransaction['amount'] || creditTransaction['amount'] == 0">
                                                    &#8377; 0
                                                </h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="creditTransactions && creditTransactions.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let creditTransaction of creditTransactions; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-12">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{creditTransaction?.amount}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Transaction Id - {{creditTransaction?.transaction_id}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    User Type - {{creditTransaction?.user_type}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - {{creditTransaction?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Code - {{creditTransaction?.code}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Date - {{creditTransaction?.date}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Amount - {{creditTransaction?.amount}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious>
                        <span [data-feather]="'chevron-left'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <span [data-feather]="'chevron-right'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
