import {Component, OnInit} from '@angular/core';
import {BusinessHeadCenterService} from '../../../shared/services/business-head-center.service';
import {StorageService} from '../../../shared/services/storage.service';
import {BusinessAdminService} from '../../../shared/services/business-admin.service';
import {BusinessCenterService} from '../../../shared/services/business-center.service';

@Component({
    selector: 'app-back-session-entry-university-setting',
    templateUrl: './back-session-entry-university-setting.component.html',
    styleUrls: ['./back-session-entry-university-setting.component.scss']
})
export class BackSessionEntryUniversitySettingComponent implements OnInit {
    user: any;
    admin: any;
    business_head_centers: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private businessHeadcenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService,
                private businessCenterService: BusinessCenterService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.user = StorageService.getItem('self');
        this.getUniversityAdmin();
    }

    getUniversityAdmin() {
        this.businessAdminService.universityBusinessAdmin(this.user['userId']).subscribe(data => {
            this.admin = data;
            this.get();
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.businessHeadcenterService.get(this.admin['user_id'], {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.business_head_centers = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.business_head_centers = [];
            }
        });
    }

    getUniversityCenter(headCenter) {
        this.businessCenterService.get(this.admin['user_id'], headCenter['id'], {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                headCenter['business_centers'] = data['data'];
            }
        });
    }

    selectHeadCenter(headCenter) {
        //console.log('headCenter', headCenter);
    }

    selectCenter(center) {
        //console.log('center', center);
    }
}
