import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BusinessHeadCenterService} from '../../../shared/services/business-head-center.service';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
    selector: 'app-business-head-center-share-percentage',
    templateUrl: './business-head-center-share-percentage.component.html',
    styleUrls: ['./business-head-center-share-percentage.component.scss']
})
export class BusinessHeadCenterSharePercentageComponent implements OnInit {
    user: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 1,
        pages: null
    };
    contentHeader: object;
    businessHeadCenter: any;
    headCenterId: any;
    university: any;
    business_university_settings: any = [];
    universitySession: any;
    updateSessionPercentageForm: FormGroup;
    submitted: any = false;
    submitting: any = false;

    constructor(private activatedRoute: ActivatedRoute,
                private businessHeadCenterService: BusinessHeadCenterService,
                private universitySettingService: UniversitySettingService,
                private fb: FormBuilder,
                public modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.user = StorageService.getItem('self');
        this.headCenterId = this.activatedRoute.snapshot.params['id'];
        this.updateSessionPercentageForm = this.fb.group({
            'admission_percentage_actual': ['', Validators.required],
            're_registration_percentage_actual': ['', Validators.required],
            'admission_percentage_display': ['', Validators.required],
            're_registration_percentage_display': ['', Validators.required],
            'prospectus_fee': [''],
            'service_charge': [''],
            'examination_fee': [''],
        });
        this.headCenterDetail();
    }

    headCenterDetail() {
        this.businessHeadCenterService.detailById(this.headCenterId).subscribe(data => {
            if (data && data['business_admin_detail'] && data['business_admin_detail']['university'] && data['business_admin_detail']['university'].id) {
                this.businessHeadCenter = (data && data['self']) ? data['self'] : '';
                this.university = data['business_admin_detail']['university'];
                this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }
        });
    }

    getUniversitySetting(params?) {
        this.universitySettingService.getByAdmin(this.university['id'], params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.business_university_settings = data['data'];
                if (this.business_university_settings && this.business_university_settings.length > 0) {
                    let headCenterId = this.headCenterId;
                    this.business_university_settings.forEach(business_university_setting => {
                        if (business_university_setting && business_university_setting['business_head_center_share_percentage'] && business_university_setting['business_head_center_share_percentage'].length > 0) {
                            business_university_setting['business_head_center_share_percentage'].forEach(business_head_center_share_percentage => {
                                if (parseInt(headCenterId) === parseInt(business_head_center_share_percentage['head_center_id'])) {
                                    business_university_setting['admission_percentage_actual'] = business_head_center_share_percentage['admission_percentage_actual'];
                                    business_university_setting['re_registration_percentage_actual'] = business_head_center_share_percentage['re_registration_percentage_actual'];
                                    business_university_setting['admission_percentage_display'] = business_head_center_share_percentage['admission_percentage_display'];
                                    business_university_setting['re_registration_percentage_display'] = business_head_center_share_percentage['re_registration_percentage_display'];
                                    business_university_setting['prospectus_fee'] = business_head_center_share_percentage['prospectus_fee'];
                                    business_university_setting['service_fee'] = business_head_center_share_percentage['service_fee'];
                                    business_university_setting['examination_fee'] = business_head_center_share_percentage['examination_fee'];
                                }
                            });
                        }
                    });
                }
            }
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        this.getUniversitySetting();
    }

    updatePercentageModal(content, universitySession) {
        this.universitySession = universitySession;
        this.updateSessionPercentageForm.patchValue({
            'admission_percentage_actual': (this.universitySession && this.universitySession['admission_percentage_actual']) ? this.universitySession['admission_percentage_actual'] : '',
            're_registration_percentage_actual': (this.universitySession && this.universitySession['re_registration_percentage_actual']) ? this.universitySession['re_registration_percentage_actual'] : '',
            'admission_percentage_display': (this.universitySession && this.universitySession['admission_percentage_display']) ? this.universitySession['admission_percentage_display'] : '',
            're_registration_percentage_display': (this.universitySession && this.universitySession['re_registration_percentage_display']) ? this.universitySession['re_registration_percentage_display'] : '',
            'prospectus_fee': (this.universitySession && this.universitySession['prospectus_fee']) ? this.universitySession['prospectus_fee'] : '',
            'service_charge': (this.universitySession && this.universitySession['service_charge']) ? this.universitySession['service_charge'] : '',
            'examination_fee': (this.universitySession && this.universitySession['examination_fee']) ? this.universitySession['examination_fee'] : '',
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    create() {
        this.submitted = true;
        if (!this.updateSessionPercentageForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'head_center_id': this.headCenterId,
                'admission_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_actual) ? this.updateSessionPercentageForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_actual) ? this.updateSessionPercentageForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_display) ? this.updateSessionPercentageForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_display) ? this.updateSessionPercentageForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.prospectus_fee) ? this.updateSessionPercentageForm.value.prospectus_fee : '',
                'service_charge': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.service_charge) ? this.updateSessionPercentageForm.value.service_charge : '',
                'examination_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.examination_fee) ? this.updateSessionPercentageForm.value.examination_fee : '',
                'setting_id': (this.universitySession) ? this.universitySession.id : ''
            };
            this.submitting = true;
            this.businessHeadCenterService.createSharePercentage(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateSessionPercentageForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': '',
                    'service_charge': '',
                    'examination_fee': '',
                });
                this.modalService.dismissAll('');
                this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.updateSessionPercentageForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'head_center_id': this.headCenterId,
                'admission_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_actual) ? this.updateSessionPercentageForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_actual) ? this.updateSessionPercentageForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_display) ? this.updateSessionPercentageForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_display) ? this.updateSessionPercentageForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.prospectus_fee) ? this.updateSessionPercentageForm.value.prospectus_fee : '',
                'service_charge': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.service_charge) ? this.updateSessionPercentageForm.value.service_charge : '',
                'examination_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.examination_fee) ? this.updateSessionPercentageForm.value.examination_fee : '',
            };
            this.submitting = true;
            this.businessHeadCenterService.updateSharePercentage(this.universitySession.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateSessionPercentageForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': '',
                    'service_charge': '',
                    'examination_fee': '',
                });
                this.modalService.dismissAll('');
                this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
