import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';
import {AccountingService} from '../../shared/services/accounting.service';
import {BusinessCenterService} from '../../shared/services/business-center.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {StudentService} from '../../shared/services/student.service';
import {Helper} from '../../shared/constants/helper';

@Component({
    selector: 'app-accounting',
    templateUrl: './accounting.component.html',
    styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {
    modalType: any;
    user: any;
    submitting: any = false;
    transaction = 0;
    receiptTotal = 0;
    receiptPending = 0;
    receiptAccepted = 0;
    totalStudent = 0;
    businessCenterDetail: any;
    businessHeadCenterDetail: any;
    creditsProcessable = 0;
    studentsProcessable = 0;
    studentsProcessing = 0;
    studentsProcessed = 0;
    accountingData: any;
    decodedOtp: any;
    accountingOtpForm: FormGroup;
    submitted: any = false;
    timerSixtySeconds: number = 60;
    interval: any;
    studentSessionForm: FormGroup;
    settings: any = [];
    bulkReceiptData: any = [];
    showFetchingDataLoader: any = false;
    showUpdatingDataLoader: any = false;
    onFileChanged: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute,
                private accountingService: AccountingService,
                private businessCenterService: BusinessCenterService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService,
                private universitySettingService: UniversitySettingService,
                private studentService: StudentService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        if (this.user && this.user['user_type'] && (this.user['user_type'] !== 'ACCOUNTING' && this.user['user_type'] !== 'BUSINESS_HEAD_CENTER' && this.user['user_type'] !== 'BUSINESS_CENTER')) {
            this.router.navigateByUrl('dashboard');
        }
        this.accountingOtpForm = this.fb.group({
            'otp': ['', Validators.required]
        });
        this.studentSessionForm = this.fb.group({
            'student_session_year': ['']
        });
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') {
            this.getSettings();
        }
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getSettings();
        });
    }

    getSettings() {
        let universityId = (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') ? 10 : this.businessHeadCenterDetail['business_admin_detail']['university_id'];
        this.universitySettingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.settings = data['data'];
                this.patchSettingForm();
            } else {
                this.settings = [];
            }
        });
    }

    patchSettingForm() {
        if (this.settings && this.settings.length > 0) {
            let selectedSetting: any;
            this.settings.forEach(setting => {
                if (setting && setting['session_default'] && setting['session_default'] === 'ACTIVE') {
                    selectedSetting = setting;
                }
            });
            this.studentSessionForm.patchValue({
                'student_session_year': selectedSetting.id
            });
            if (this.user && this.user['user_type'] && this.user['user_type'] !== 'ACCOUNTING') {
                if (this.user && this.user['user_type'] && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                    this.accountingStatus();
                } else {
                    this.router.navigateByUrl('dashboard');
                }
            } else {
                this.refresh();
            }
        }
    }

    accountingStatus() {
        this.accountingData = StorageService.getItem('accounting');
        if (this.accountingData) {
            this.refresh();
        } else {
            if (this.user && this.user['user_type'] && (this.user['user_type'] === 'BUSINESS_HEAD_CENTER' || this.user['user_type'] === 'BUSINESS_CENTER')) {
                this.verifyAccountingUser();
            }
        }
    }

    refresh() {
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'ACCOUNTING') {
            this.transactionTotal();
            this.receiptsTotal();
            this.receiptsAccepted();
            this.receiptsPending();
        } else if (this.user && this.user['user_type'] && (this.user['user_type'] === 'BUSINESS_CENTER' || this.user['user_type'] === 'BUSINESS_HEAD_CENTER')) {
            this.getBusinessCenterDetail();
            this.getStudentTotal();
            this.getStudentsProcessable();
            this.getStudentsProcessing();
            this.getStudentsProcessed();
        }
    }

    verifyAccountingUser() {
        this.submitting = true;
        this.accountingService.verifyAccountingUser({}).subscribe(data => {
            if (data && data['decoded_otp']) {
                this.decodedOtp = data['decoded_otp'];
                this.submitting = false;
                this.resendOTPTimer();
            }
        }, error => {
            this.submitting = false;
        });
    }

    resendOTPTimer() {
        this.timerSixtySeconds = 60;
        this.interval = setInterval(() => {
            if (this.timerSixtySeconds > 0) {
                this.timerSixtySeconds--;
            } else {
                this.interval = undefined;
            }
        }, 1000);
    }

    resendOTP() {
        this.submitting = true;
        this.accountingService.resendOTP({}).subscribe(data => {
            if (data && data['decoded_otp']) {
                this.decodedOtp = data['decoded_otp'];
                this.resendOTPTimer();
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    transactionTotal() {
        this.submitting = true;
        this.accountingService.getTransactionTotal({'setting_id': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.transaction = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    receiptsTotal() {
        this.submitting = true;
        this.accountingService.getReceiptTotal({'setting_id': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.receiptTotal = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    receiptsAccepted() {
        this.submitting = true;
        this.accountingService.getReceiptAccepeted({'setting_id': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.receiptAccepted = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    receiptsPending() {
        this.submitting = true;
        this.accountingService.getReceiptPending({'setting_id': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.receiptPending = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getStudentTotal() {
        this.submitting = true;
        this.accountingService.getTotalStudent({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.totalStudent = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getStudentsProcessable() {
        this.submitting = true;
        this.accountingService.getStudentsProcessable({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.studentsProcessable = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getStudentsProcessing() {
        this.submitting = true;
        this.accountingService.getStudentsProcessing({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.studentsProcessing = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getStudentsProcessed() {
        this.submitting = true;
        this.accountingService.getStudentsProcessed({'admission_session': this.studentSessionForm.value.student_session_year}).subscribe(data => {
            this.studentsProcessed = data;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getBusinessCenterDetail() {
        if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.businessCenterService.detailById(this.user['userId']).subscribe(data => {
                this.businessCenterDetail = data;
                this.creditsProcessable = (this.businessCenterDetail && this.businessCenterDetail['amount']) ? this.businessCenterDetail['amount'] : 0;
            });
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
                this.businessHeadCenterDetail = data;
                this.creditsProcessable = (this.businessHeadCenterDetail && this.businessHeadCenterDetail['amount']) ? this.businessHeadCenterDetail['amount'] : 0;
            });
        }
    }

    goToReceipt() {
        this.router.navigateByUrl('accounting/receipts');
    }

    goToTransactions() {
        this.router.navigateByUrl('accounting/credit-transactions');
    }

    goToProcessStudent() {
        this.router.navigateByUrl('accounting/process-students');
    }

    submitOtp() {
        this.submitted = true;
        if (!this.accountingOtpForm.valid) {
            return;
        }
        let params = {
            'otp': (this.accountingOtpForm.value && this.accountingOtpForm.value['otp']) ? this.accountingOtpForm.value['otp'] : '',
            'decoded_otp': this.decodedOtp
        };
        this.accountingService.accoutingOtp(params).subscribe(data => {
            if (data) {
                StorageService.setItem('accounting', this.user);
                this.decodedOtp = undefined;
                this.submitting = false;
                this.submitted = false;
                this.refresh();
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

    changeSettings(event) {
        if (event && event.target && event.target.value && this.settings && this.settings.length > 0) {
            let selectedSetting = {};
            this.settings.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSetting = session;
                }
            });
            this.refresh();
        }
    }

    openReceiptBulkUploadModal(content) {
        this.modalType = 'RECEIPT_BULK_UPLOAD';
        this.bulkReceiptData = [];
        this.modalService.open(content, {'size': 'xl'});
    }

    submitBulkReceipt() {
        if (this.bulkReceiptData && this.bulkReceiptData.length > 0) {
            this.bulkReceiptData.forEach(data => {
                data['errors']['type_message'] = (!(data && data.type && data.type != ''));
                data['errors']['code_message'] = (!(data && data.code && data.code != ''));
                data['errors']['amount_message'] = (!(data && data.amount && data.amount != ''));
                data['errors']['date_message'] = (!(data && data.date && data.date != ''));
            });

            let errorCount = 0;
            if (this.bulkReceiptData && this.bulkReceiptData.length > 0) {
                this.bulkReceiptData.forEach(bulkReceipt => {
                    if (bulkReceipt && bulkReceipt.errors && (bulkReceipt.errors.type_message || bulkReceipt.errors.amount_message || bulkReceipt.errors.amount_message || bulkReceipt.errors.date_message)) {
                        errorCount++;
                    }
                });
            }

            if (!errorCount) {
                this.submitting = true;
                this.submitted = true;
                this.showUpdatingDataLoader = true;
                this.studentService.bulkReceipt({
                    'setting_id': (this.studentSessionForm.value && this.studentSessionForm.value.student_session_year) ? this.studentSessionForm.value.student_session_year : '',
                    'receipts': this.bulkReceiptData
                }).subscribe(data => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                    this.bulkReceiptData = [];
                    this.modalService.dismissAll('');
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                });
            } else {
                this.alertService.showErrors('Error in uploaded excel, please check and upload again');
            }
        } else {
            this.alertService.showErrors('No student data found');
        }
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.studentService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'STUDENT_BULK_RECEIPT_UPLOAD') {
                this.studentBulkReceiptUpload(data['data'].url);
            }
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    studentBulkReceiptUpload(url) {
        this.showFetchingDataLoader = true;
        this.studentService.studentBulkReceiptUpload({'url': url}).subscribe(data => {
            if (data) {
                this.bulkReceiptData = data;
                this.showFetchingDataLoader = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            this.showFetchingDataLoader = false;
        });
    }

    removeProId(index) {
        this.bulkReceiptData.splice(index, 1);
    }
}
