import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportsRoutingModule} from './reports-routing.module';
import {ReportsComponent} from './reports.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {CentersReportComponent} from './centers-report/centers-report.component';
import { CentersStudentsComponent } from './centers-students/centers-students.component';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import { HeadCentersReportComponent } from './head-centers-report/head-centers-report.component';
import { HeadCentersStudentsComponent } from './head-centers-students/head-centers-students.component';

@NgModule({
    declarations: [ReportsComponent, CentersReportComponent, CentersStudentsComponent, HeadCentersReportComponent, HeadCentersStudentsComponent],
    imports: [
        CommonModule,
        ReportsRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        CoreDirectivesModule
    ],
    exports: [ReportsComponent]
})

export class ReportsModule {
}
