import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessAdminCentersComponent} from './business-admin-centers.component';
import {AddBusinessAdminCenterComponent} from './add-business-admin-center/add-business-admin-center.component';

const routes: Routes = [
    {
        path: 'business-admin-centers',
        component: BusinessAdminCentersComponent,
        data: {animation: 'business-admin-centers'}
    },
    {
        path: 'add-business-admin-center',
        component: AddBusinessAdminCenterComponent,
        data: {animation: 'add-business-admin-center'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BusinessAdminCentersRoutingModule {
}
