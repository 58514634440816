import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessHeadCenterShareComponent} from './business-head-center-share.component';

const routes: Routes = [
    {
        path: 'business-head-centers-share',
        component: BusinessHeadCenterShareComponent,
        data: {animation: 'business-head-centers-share'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessHeadCenterShareRoutingModule {
}
