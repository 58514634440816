import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessAdminCentersRoutingModule} from './business-admin-centers-routing.module';
import {BusinessAdminCentersComponent} from './business-admin-centers.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {AddBusinessAdminCenterComponent} from './add-business-admin-center/add-business-admin-center.component';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';

@NgModule({
    declarations: [BusinessAdminCentersComponent, AddBusinessAdminCenterComponent],
    imports: [
        CommonModule,
        BusinessAdminCentersRoutingModule,
        NgbPaginationModule,
        CoreCommonModule,
        CoreSidebarModule,
    ],
    exports: [
        BusinessAdminCentersComponent, AddBusinessAdminCenterComponent
    ]
})

export class BusinessAdminCentersModule {
}
