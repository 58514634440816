import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReportsComponent} from './reports.component';
import {CentersReportComponent} from './centers-report/centers-report.component';
import {CentersStudentsComponent} from './centers-students/centers-students.component';
import {HeadCentersReportComponent} from './head-centers-report/head-centers-report.component';
import {HeadCentersStudentsComponent} from './head-centers-students/head-centers-students.component';

const routes: Routes = [
    {
        path: 'reports',
        component: ReportsComponent,
        data: {animation: 'reports'}
    },
    {
        path: 'reports/centers-report',
        component: CentersReportComponent,
        data: {animation: 'centers-reports'}
    },
    {
        path: 'reports/head-centers-report',
        component: HeadCentersReportComponent,
        data: {animation: 'head-centers-reports'}
    },
    {
        path: 'reports/centers-students/:id',
        component: CentersStudentsComponent,
        data: {animation: 'centers-students'}
    },
    {
        path: 'reports/head-centers-students/:id',
        component: HeadCentersStudentsComponent,
        data: {animation: 'head-centers-students'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ReportsRoutingModule {
}
