import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessCentersComponent} from './business-centers.component';
import {BusinessCenterSharePercentageComponent} from './business-center-share-percentage/business-center-share-percentage.component';

const routes: Routes = [
    {
        path: 'business-centers',
        component: BusinessCentersComponent,
        data: {animation: 'business-centers'}
    },
    {
        path: 'business-admin/:adminId/business-head-center/:id/business-centers',
        component: BusinessCentersComponent,
        data: {animation: 'business-admin/:adminId/business-head-center/:id/business-centers'}
    },
    {
        path: 'business-head-center/:headCenterId/business-center/:id/business-center-share-percentage',
        component: BusinessCenterSharePercentageComponent,
        data: {animation: 'business-head-center/:headCenterId/business-center/:id/business-center-share-percentage'}
    },
    {
        path: 'business-admin/:adminId/business-head-center/:headCenterId/business-center/:id/business-center-share-percentage',
        component: BusinessCenterSharePercentageComponent,
        data: {animation: 'business-admin/:adminId/business-head-center/:headCenterId/business-center/:id/business-center-share-percentage'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessCentersRoutingModule {
}
