import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class AdmissionFormService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.ADMISSION_FORM());
    }

    getByCounsellor(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.ADMISSION_FORM(id));
    }

    detail(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.ADMISSION_FORM(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.ADMISSION_FORM(), data);
    }

    translate(data): Observable<any> {
        return this.postRequest(AppUrl.TRANSLATE_TEXT(), data, {success: null, failure: null}, true);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM(id), data);
    }

    updatePhotograph(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_PHOTOGRAPH_UPDATE(id), data);
    }

    updatePCBMapping(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_UPDATE_PCB_MAPPING(id), data);
    }

    updateTenthCertificate(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_TENTH_CERTIFICATE_UPDATE(id), data);
    }

    updateTwelthCertificate(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_TWELTH_CERTIFICATE_UPDATE(id), data);
    }

    updateGraduateCertificate(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_GRADUATE_CERTIFICATE_UPDATE(id), data);
    }

    updatePostGraduateCertificate(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_POST_GRADUATE_CERTIFICATE_UPDATE(id), data);
    }

    updateOtherCertificate(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_OTHER_CERTIFICATE_UPDATE(id), data);
    }

    updateGovtPhotoId(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMISSION_FORM_GOVT_PHOTO_ID_UPDATE(id), data);
    }

    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }
}
