import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class CourseCurriculumService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.COURSES_CURRICULUM(), data);
    }

    /*getByAdmin(id, data): Observable<any> {
        return this.getRequest(AppUrl.UNIVERSITY_COURSES(id), data);
    }*/

    count(): Observable<any> {
        return this.getRequest(AppUrl.COURSES_CURRICULUM_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.COURSES_CURRICULUM(id));
    }

    detailByPCBId(id): Observable<any> {
        return this.getRequest(AppUrl.COURSES_CURRICULUM_BY_ID(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.COURSES_CURRICULUM(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.COURSES_CURRICULUM(id), data);
    }

    /*updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.COURSES_PASSWORD(id), data);
    }*/

    /*updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.COURSES_STATUS(id), data);
    }*/
}
