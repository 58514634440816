import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';
import {Helper} from '../../../shared/constants/helper';

@Component({
    selector: 'app-add-setting',
    templateUrl: './add-university-setting.component.html',
    styleUrls: ['./add-university-setting.component.scss']
})
export class AddUniversitySettingComponent implements OnChanges {
    addSettingForm: FormGroup;
    id: any;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    years = Helper.getYearArray(2019);
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private universitySettingService: UniversitySettingService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-setting').toggleOpen();
    }

    refresh() {
        this.addSettingForm = this.fb.group({
            'session_year': ['', Validators.required],
            'session_name': ['', Validators.required],
            'student_application_id_prefix': ['', Validators.required],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addSettingForm.patchValue({
            'session_year': (this.data && this.data['session_year']) ? this.data['session_year'] : '',
            'session_name': (this.data && this.data['session_name']) ? this.data['session_name'] : '',
            'student_application_id_prefix': (this.data && this.data['student_application_id_prefix']) ? this.data['student_application_id_prefix'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addSettingForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'session_year': (this.addSettingForm.value && this.addSettingForm.value.session_year) ? this.addSettingForm.value.session_year : '',
                'session_name': (this.addSettingForm.value && this.addSettingForm.value.session_name) ? this.addSettingForm.value.session_name : '',
                'student_application_id_prefix': (this.addSettingForm.value && this.addSettingForm.value.student_application_id_prefix) ? this.addSettingForm.value.student_application_id_prefix : '',
            };
            this.submitting = true;
            this.universitySettingService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].session_year && error['error']['errors'].session_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].session_year[0]);
                        return;
                    }
                    if (error['error']['errors'].session_name && error['error']['errors'].session_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].session_name[0]);
                        return;
                    }
                    if (error['error']['errors'].student_application_id_prefix && error['error']['errors'].student_application_id_prefix.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_application_id_prefix[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addSettingForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'session_year': (this.addSettingForm.value && this.addSettingForm.value.session_year) ? this.addSettingForm.value.session_year : '',
                'session_name': (this.addSettingForm.value && this.addSettingForm.value.session_name) ? this.addSettingForm.value.session_name : '',
                'student_application_id_prefix': (this.addSettingForm.value && this.addSettingForm.value.student_application_id_prefix) ? this.addSettingForm.value.student_application_id_prefix : '',
            };
            this.submitting = true;
            this.universitySettingService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].session_year && error['error']['errors'].session_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].session_year[0]);
                        return;
                    }
                    if (error['error']['errors'].session_name && error['error']['errors'].session_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].session_name[0]);
                        return;
                    }
                    if (error['error']['errors'].student_application_id_prefix && error['error']['errors'].student_application_id_prefix.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_application_id_prefix[0]);
                        return;
                    }
                }
            });
        }
    }
}
