import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UniversitySettingsRoutingModule} from './university-settings-routing.module';
import {UniversitySettingsComponent} from './university-settings.component';
import {AddUniversitySettingComponent} from './add-university-setting/add-university-setting.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {UiSwitchModule} from 'ngx-ui-switch';
import {BackSessionEntryUniversitySettingComponent} from './back-session-entry-university-setting/back-session-entry-university-setting.component';

@NgModule({
    declarations: [UniversitySettingsComponent, AddUniversitySettingComponent, BackSessionEntryUniversitySettingComponent],
    imports: [
        CommonModule,
        UniversitySettingsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        UiSwitchModule
    ],
    exports: [UniversitySettingsComponent, AddUniversitySettingComponent, BackSessionEntryUniversitySettingComponent]
})
export class UniversitySettingsModule {
}
