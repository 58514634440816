import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from './notifications.component';
import {AddNotificationComponent} from './add-notification/add-notification.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationsRoutingModule} from './notifications-routing.module';
import {UiSwitchModule} from 'ngx-ui-switch';

@NgModule({
    declarations: [NotificationsComponent, AddNotificationComponent],
    imports: [
        CommonModule,
        NotificationsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        UiSwitchModule
    ],
    exports: [NotificationsComponent, AddNotificationComponent]
})

export class NotificationsModule {
}
