import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
    static markingSchemes = [
        {'name': 'Percentage', 'value': 'PERCENTAGE'},
        {'name': 'CGPA', 'value': 'CGPA'}
    ];

    static boardUniversity = [
        {'name': 'CBSE', 'value': 'CBSE'},
        {'name': 'ICSE', 'value': 'ICSE'},
        {'name': 'IB', 'value': 'IB'},
        {'name': 'State Board', 'value': 'STATE_BOARD'}
    ];


    static lotstatuses = [
        {
            'name': 'LOT 1',
            'value': 'LOT1'
        },
        {
            'name': 'LOT 2',
            'value': 'LOT2'
        },
        {
            'name': 'LOT 3',
            'value': 'LOT3'
        },
        {
            'name': 'LOT 4',
            'value': 'LOT4'
        },
        {
            'name': 'LOT 5',
            'value': 'LOT5'
        }
    ];


    static statuses = [
        {
            'name': 'Pending',
            'value': 'PENDING'
        },
        {
            'name': 'Form Received',
            'value': 'FORM_RECEIVED'
        },
        {
            'name': 'Documents Required',
            'value': 'DOCUMENTS_REQUIRED'
        },
        {
            'name': 'Documents Uploaded',
            'value': 'DOCUMENTS_UPLOADED'
        },
        {
            'name': 'Documents Re Uploaded',
            'value': 'DOCUMENTS_RE_UPLOADED'
        },
        {
            'name': 'Sent',
            'value': 'SENT'
        }
    ];

    static MS_HEAD_CENTER_ID = 436;

    static SUBHARTI_UNINVERSITY_ID = 10;
}
