import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class CreditTransactionService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.CREDIT_TRANSACTION(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.CREDIT_TRANSACTION_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.CREDIT_TRANSACTION(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.CREDIT_TRANSACTION(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.CREDIT_TRANSACTION(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.CREDIT_TRANSACTION_STATUS(id), data);
    }

    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }
}
