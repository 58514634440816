import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {AdminService} from '../../../shared/services/admin.service';
import {BusinessAdminService} from '../../../shared/services/business-admin.service';

@Component({
    selector: 'app-add-b-admins',
    templateUrl: './add-business-admins.component.html',
    styleUrls: ['./add-business-admins.component.scss']
})
export class AddBusinessAdminsComponent implements OnChanges {
    addBusinessAdminForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private businessAdminService: BusinessAdminService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-admin').toggleOpen();
    }

    refresh() {
        this.addBusinessAdminForm = this.fb.group({
            'name': ['', Validators.required],
            'username': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'password': ['', (!this.data || !this.data['id']) ? Validators.required : '']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addBusinessAdminForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'username': (this.data && this.data['username']) ? this.data['username'] : '',
            'email': (this.data && this.data['email']) ? this.data['email'] : '',
            'contact': (this.data && this.data['contact']) ? this.data['contact'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addBusinessAdminForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.name) ? this.addBusinessAdminForm.value.name : '',
                'username': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.username) ? this.addBusinessAdminForm.value.username : '',
                'email': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.email) ? this.addBusinessAdminForm.value.email : '',
                'contact': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.contact) ? this.addBusinessAdminForm.value.contact : '',
                'password': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.password) ? this.addBusinessAdminForm.value.password : ''
            };
            this.submitting = true;
            this.businessAdminService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addBusinessAdminForm.reset();
                    this.addBusinessAdminForm.patchValue({
                        'name': '',
                        'username': '',
                        'email': '',
                        'contact': '',
                        'password': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addBusinessAdminForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.name) ? this.addBusinessAdminForm.value.name : '',
                'username': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.username) ? this.addBusinessAdminForm.value.username : '',
                'email': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.email) ? this.addBusinessAdminForm.value.email : '',
                'contact': (this.addBusinessAdminForm.value && this.addBusinessAdminForm.value.contact) ? this.addBusinessAdminForm.value.contact : ''
            };
            this.submitting = true;
            this.businessAdminService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }
}
