import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UniversitySettingService} from '../../../shared/services/university-setting.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessCenterService} from '../../../shared/services/business-center.service';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
    selector: 'app-business-center-share-percentage',
    templateUrl: './business-center-share-percentage.component.html',
    styleUrls: ['./business-center-share-percentage.component.scss']
})
export class BusinessCenterSharePercentageComponent implements OnInit {
    user: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    contentHeader: object;
    businessCenter: any;
    centerId: any;
    university: any;
    business_university_settings: any = [];
    universitySession: any;
    updateSessionPercentageForm: FormGroup;
    submitted: any = false;
    submitting: any = false;

    constructor(private activatedRoute: ActivatedRoute,
                private businessCenterService: BusinessCenterService,
                private universitySettingService: UniversitySettingService,
                private fb: FormBuilder,
                private router: Router,
                public modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        if (this.user && this.user['user_type'] && (this.user['user_type'] !== 'SUPER_ADMIN' && this.user['user_type'] !== 'BUSINESS_ADMIN')) {
            this.router.navigateByUrl('dashboard');
        }
        this.refresh();
    }

    refresh() {
        this.centerId = this.activatedRoute.snapshot.params['id'];
        this.updateSessionPercentageForm = this.fb.group({
            'admission_percentage_actual': ['', Validators.required],
            're_registration_percentage_actual': ['', Validators.required],
            'admission_percentage_display': ['', Validators.required],
            're_registration_percentage_display': ['', Validators.required],
            'prospectus_fee': ['', Validators.required],
            'service_charge': ['', Validators.required],
            'examination_fee': ['', Validators.required],
        });
        this.centerDetail();
    }

    centerDetail() {
        this.businessCenterService.detailById(this.centerId).subscribe(data => {
            if (data && data['business_admin_detail'] && data['business_admin_detail']['university'] && data['business_admin_detail']['university'].id) {
                this.businessCenter = (data && data['self']) ? data['self'] : '';
                this.university = data['business_admin_detail']['university'];
                this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }
        });
    }

    getUniversitySetting(params?) {
        this.universitySettingService.getByAdmin(this.university['id'], params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.business_university_settings = data['data'];
                if (this.business_university_settings && this.business_university_settings.length > 0) {
                    let centerId = this.centerId;
                    this.business_university_settings.forEach(business_university_setting => {
                        if (business_university_setting && business_university_setting['business_center_share_percentage'] && business_university_setting['business_center_share_percentage'].length > 0) {
                            business_university_setting['business_center_share_percentage'].forEach(business_center_share_percentage => {
                                if (parseInt(centerId) === parseInt(business_center_share_percentage['center_id'])) {
                                    business_university_setting['admission_percentage_actual'] = business_center_share_percentage['admission_percentage_actual'];
                                    business_university_setting['re_registration_percentage_actual'] = business_center_share_percentage['re_registration_percentage_actual'];
                                    business_university_setting['admission_percentage_display'] = business_center_share_percentage['admission_percentage_display'];
                                    business_university_setting['re_registration_percentage_display'] = business_center_share_percentage['re_registration_percentage_display'];
                                    business_university_setting['prospectus_fee'] = business_center_share_percentage['prospectus_fee'];
                                    business_university_setting['service_fee'] = business_center_share_percentage['service_fee'];
                                    business_university_setting['examination_fee'] = business_center_share_percentage['examination_fee'];
                                }
                            });
                        }
                    });
                }
            }
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        this.getUniversitySetting();
    }

    updatePercentageModal(content, universitySession) {
        this.universitySession = universitySession;
        if (this.universitySession && this.universitySession['business_center_share_percentage']) {
            this.updateSessionPercentageForm.patchValue({
                'admission_percentage_actual': (this.universitySession && this.universitySession['admission_percentage_actual']) ? this.universitySession['admission_percentage_actual'] : '',
                're_registration_percentage_actual': (this.universitySession && this.universitySession['re_registration_percentage_actual']) ? this.universitySession['re_registration_percentage_actual'] : '',
                'admission_percentage_display': (this.universitySession && this.universitySession['admission_percentage_display']) ? this.universitySession['admission_percentage_display'] : '',
                're_registration_percentage_display': (this.universitySession && this.universitySession['re_registration_percentage_display']) ? this.universitySession['re_registration_percentage_display'] : '',
                'prospectus_fee': (this.universitySession && this.universitySession['prospectus_fee']) ? this.universitySession['prospectus_fee'] : '',
                'service_charge': (this.universitySession && this.universitySession['service_charge']) ? this.universitySession['service_charge'] : '',
                'examination_fee': (this.universitySession && this.universitySession['examination_fee']) ? this.universitySession['examination_fee'] : ''
            });
        }
        this.modalService.open(content, {'size': 'sm'});
    }

    create() {
        this.submitted = true;
        if (!this.updateSessionPercentageForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'center_id': this.centerId,
                'admission_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_actual) ? this.updateSessionPercentageForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_actual) ? this.updateSessionPercentageForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_display) ? this.updateSessionPercentageForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_display) ? this.updateSessionPercentageForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.prospectus_fee) ? this.updateSessionPercentageForm.value.prospectus_fee : '',
                'service_charge': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.service_charge) ? this.updateSessionPercentageForm.value.service_charge : '',
                'examination_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.examination_fee) ? this.updateSessionPercentageForm.value.examination_fee : '',
                'setting_id': (this.universitySession) ? this.universitySession.id : ''
            };
            this.submitting = true;
            this.businessCenterService.createSharePercentage(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateSessionPercentageForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                });
                this.modalService.dismissAll('');
                this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.updateSessionPercentageForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'center_id': this.centerId,
                'admission_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_actual) ? this.updateSessionPercentageForm.value.admission_percentage_actual : '',
                're_registration_percentage_actual': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_actual) ? this.updateSessionPercentageForm.value.re_registration_percentage_actual : '',
                'admission_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.admission_percentage_display) ? this.updateSessionPercentageForm.value.admission_percentage_display : '',
                're_registration_percentage_display': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.re_registration_percentage_display) ? this.updateSessionPercentageForm.value.re_registration_percentage_display : '',
                'prospectus_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.prospectus_fee) ? this.updateSessionPercentageForm.value.prospectus_fee : '',
                'service_charge': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.service_charge) ? this.updateSessionPercentageForm.value.service_charge : '',
                'examination_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.examination_fee) ? this.updateSessionPercentageForm.value.examination_fee : '',
            };
            this.submitting = true;
            this.businessCenterService.updateSharePercentage(this.universitySession.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateSessionPercentageForm.patchValue({
                    'admission_percentage_actual': '',
                    're_registration_percentage_actual': '',
                    'admission_percentage_display': '',
                    're_registration_percentage_display': '',
                    'prospectus_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.prospectus_fee) ? this.updateSessionPercentageForm.value.prospectus_fee : '',
                    'service_charge': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.service_charge) ? this.updateSessionPercentageForm.value.service_charge : '',
                    'examination_fee': (this.updateSessionPercentageForm.value && this.updateSessionPercentageForm.value.examination_fee) ? this.updateSessionPercentageForm.value.examination_fee : '',
                });
                this.modalService.dismissAll('');
                this.getUniversitySetting({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
