import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessCentersRoutingModule} from './business-centers-routing.module';
import {BusinessCentersComponent} from './business-centers.component';
import {AddBusinessCentersComponent} from './add-business-centers/add-business-centers.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {BusinessCenterSharePercentageComponent} from './business-center-share-percentage/business-center-share-percentage.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    declarations: [BusinessCentersComponent, AddBusinessCentersComponent, BusinessCenterSharePercentageComponent],
    imports: [
        CommonModule,
        BusinessCentersRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgSelectModule,
        SharedModule
    ],
    exports: [BusinessCentersComponent, AddBusinessCentersComponent]
})
export class BusinessCentersModule {
}
