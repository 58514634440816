import {Component, OnInit} from '@angular/core';
import {BusinessAdminSettingService} from '../../shared/services/business-admin-setting.service';

@Component({
    selector: 'app-business-admin-settings',
    templateUrl: './business-admin-settings.component.html',
    styleUrls: ['./business-admin-settings.component.scss']
})

export class BusinessAdminSettingsComponent implements OnInit {
    user: any;
    submitting: any = false;
    submitted: any = false;
    businessSetting: any;

    constructor(private businessAdminSettingService: BusinessAdminSettingService) {
    }

    ngOnInit(): void {
        this.get();
    }

    get() {
        this.submitting = true;
        this.businessAdminSettingService.get({}).subscribe(data => {
            this.businessSetting = data;
            this.submitting = false;
        }, error => {
            this.businessSetting = {'business_head_center_status': 'ACTIVE', 'business_center_status': 'ACTIVE'};
            this.submitting = false;
        });
    }

    businessHeadCenterStatus(event) {
        let params = {
            'status': (event) ? 'ACTIVE' : 'INACTIVE'
        };
        this.businessAdminSettingService.addHeadCenterStatus(params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

    businessCenterStatus(event) {
        let params = {
            'status': (event) ? 'ACTIVE' : 'INACTIVE'
        };
        this.businessAdminSettingService.addCenterStatus(params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }
}
