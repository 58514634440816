import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessCenterShareComponent} from './business-center-share.component';

const routes: Routes = [
    {
        path: 'business-centers-share',
        component: BusinessCenterShareComponent,
        data: {animation: 'business-centers-share'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BusinessCenterShareRoutingModule {
}
