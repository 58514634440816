<div class="content-wrapper">
    <div class="content-body">
        <section id="centers">
            <ng-template #content let-modal>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'UPDATE_PASSWORD'">Update
                            Password</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'TRANSFER_CENTER'">Transfer
                            Center</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body" ngbAutoFocus>
                        <div class="row" *ngIf="modalType === 'UPDATE_PASSWORD'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updatePasswordForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="password" class="d-flex">
                                                        Password
                                                        <i (click)="inputTypePassword = 'text'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                                           class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                                        <i (click)="inputTypePassword = 'password'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                                           class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                                    </label>
                                                    <input class="form-control" id="password" [type]="inputTypePassword"
                                                           formControlName="password">
                                                    <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row" *ngIf="modalType === 'TRANSFER_CENTER'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="transferCenterForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="head_center_id">Head Center</label>
                                                    <select class="form-control" id="head_center_id"
                                                            formControlName="head_center_id">
                                                        <option value="">Select Head Center</option>
                                                        <option [value]="headCenter.id"
                                                                *ngFor="let headCenter of headCenters">{{headCenter?.name}}
                                                            - {{headCenter?.business_head_center_detail?.code}}</option>
                                                    </select>
                                                    <small *ngIf="submitted && transferCenterForm.get('head_center_id').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="updatePassword()" *ngIf="modalType === 'UPDATE_PASSWORD'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="transferCenter()" *ngIf="modalType === 'TRANSFER_CENTER'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Transfer
                        </button>
                        <!--<button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="assignUniversity()" *ngIf="modalType === 'ASSIGN_UNIVERSITY'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>-->
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h2 class="mt-5px">Business Centers</h2>
                    <!--<app-content-header [contentHeader]="contentHeader"
                                        *ngIf="user && (user['user_type'] === 'SUPER_ADMIN' || user['user_type'] === 'ADMIN' || user['user_type'] === 'BUSINESS_ADMIN')"></app-content-header>-->
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Excel" (click)="downloadExcel()"
                            *ngIf="user && ['BUSINESS_ADMIN', 'ACCOUNTING'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-file-excel color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Search" (click)="showSearch = !showSearch">
                        <i class="fa fa-search color-white f_20 cursor-pointer p-3px"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            (click)="add()" *ngIf="(['BUSINESS_ADMIN'].indexOf(user['user_type']) !== -1)">
                        <i class="fas fa-plus-circle color-white f_20 cursor-pointer"></i>
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-xl-10 col-md-10 col-lg-10 col-sm-10 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="code" placeholder="Code">
                                </fieldset>
                            </div>
                            <div class="col-xl-2 col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Code</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Head Center</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Address</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">State</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Pin</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="businessCenters && businessCenters.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let businessCenter of businessCenters; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center" style="min-width: 200px">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(businessCenter)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Admin"
                                                   *ngIf="businessCenter?.self?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', businessCenter)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-key f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Update Password"
                                                   *ngIf="businessCenter?.self?.status === 'ACTIVE'"
                                                   (click)="updatePasswordModal(content, businessCenter?.self?.id, 'UPDATE_PASSWORD')"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Active Admin"
                                                   *ngIf="businessCenter?.self?.status === 'INACTIVE'"
                                                   (click)="updateStatus('ACTIVE', businessCenter)"></i>
                                                <i aria-hidden="true" title="Share Percentage"
                                                   class="fab fa-artstation f_20 text-primary ml-5px cursor-pointer font-weight-light mt-1"
                                                   (click)="sharePercentage(businessCenter)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-random f_20 text-primary cursor-pointer mt-1 ml-5px"
                                                   title="Transfer Center"
                                                   (click)="transferCenterModal(content, businessCenter?.self?.id, 'TRANSFER_CENTER')"></i>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.self?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.self?.username}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.code}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.business_head_center_detail?.self?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.self?.email}}</h6></td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.self?.contact}}</h6></td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.address}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{businessCenter?.state?.name}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{businessCenter?.pin}}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="11" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="businessCenters && businessCenters.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let businessCenter of businessCenters; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{businessCenter?.self?.name}}</h4>
                                </div>
                                <div class="col-6 text-right">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-5px text-danger cursor-pointer"
                                       title="In Active Admin"
                                       *ngIf="businessCenter?.self?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', businessCenter)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer"
                                       title="Active Admin"
                                       *ngIf="businessCenter?.self?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', businessCenter)"></i>
                                    <i aria-hidden="true" title="Share Percentage"
                                       class="fab fa-artstation f_20 text-primary ml-5px cursor-pointer font-weight-light"
                                       (click)="sharePercentage(businessCenter)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Username - {{businessCenter?.self?.username}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Code - {{businessCenter?.code}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Head Center - {{businessCenter?.business_head_center_detail?.self?.name}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Email - {{businessCenter?.self?.email}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{businessCenter?.self?.email}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Address - {{businessCenter?.address}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    State - {{businessCenter?.state?.name}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    State - {{businessCenter?.pin}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Password - <span class="text-info"
                                                     (click)="updatePasswordModal(content, businessCenter?.self?.id, 'UPDATE_PASSWORD')">Update</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-business-admin-center"
            overlayClass="modal-backdrop">
        <app-add-business-admin-center [data]="{'data': this.data, 'admin_id': this.user['userId']}"
                                       (valueSubmitted)="getData($event)"></app-add-business-admin-center>
    </core-sidebar>
</div>


