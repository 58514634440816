import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountingComponent} from './accounting.component';
import {ReceiptsComponent} from './receipts/receipts.component';
import {ProcessStudentsComponent} from './process-students/process-students.component';
import {CreditTransactionsComponent} from './credit-transactions/credit-transactions.component';

const routes: Routes = [
    {
        path: 'accounting',
        component: AccountingComponent,
        data: {animation: 'accounting'}
    },
    {
        path: 'accounting/receipts',
        component: ReceiptsComponent,
        data: {animation: 'accounting/receipts'}
    },
    {
        path: 'accounting/process-students',
        component: ProcessStudentsComponent,
        data: {animation: 'accounting/process-students'}
    },
    {
        path: 'accounting/credit-transactions',
        component: CreditTransactionsComponent,
        data: {animation: 'accounting/credit-transactions'}
    },
    {
        path: 'students/process-students',
        component: ProcessStudentsComponent,
        data: {animation: 'students/process-students'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AccountingRoutingModule {
}
